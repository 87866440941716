import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormGroup,
  FormControlLabel,
  IconButton,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  TextField,
  FormHelperText,
  Paper,
} from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import TermReportNSW from "../components/TermReportNSW";
import { API } from "../api";
import EditIcon from "@material-ui/icons/Edit";
import TermReportDelete from "./TermReportDelete";
import { useSnackbar } from "notistack";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { pdf } from "@react-pdf/renderer";
import { ReportPDF } from "./ReportPDF";
import { ReportPDFHK } from "./ReportPDFHK";
import { ReportPDFQLD } from "./ReportPDFQLD";
import { ReportPDFVIC } from "./ReportPDFVIC";
import { ReportPDFWA } from "./ReportPDFWA";
import TermReportQLD from "./TermReportQLD";
import TermReportVIC from "./TermReportVIC";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import PublishIcon from "@material-ui/icons/Publish";
import EmailIcon from "@material-ui/icons/Email";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { saveAs } from "file-saver";
import TermReportWA from "./TermReportWA";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop:
      sessionStorage.getItem("role") === "Campus Director" ||
      sessionStorage.getItem("role") === "State Director"
        ? theme.spacing(3)
        : theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    minWidth: 120,
  },
  formGroup: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  feedbackDialog: {
    wordBreak: "break-word",
  },
  button1: {
    marginRight: "12px",
  },
  buttonGroup: {
    marginBottom: theme.spacing(2.5),
  },
  paper: {
    padding: "24px",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const getCurrentTerm = async () => {
  const response = await API.get("/currentterm");
  return response.data;
};

function getCentreName(params) {
  return params.row.Centre.Name;
}

function getSurname(params) {
  return params.row.Student.Surname;
}

function getGivenName(params) {
  return params.row.Student.GivenName;
}

function getReportStateName(params) {
  return params.row.Term_Report_State.StateName;
}

function getCreatedBy(params) {
  return `${params.row.CreatedByStaff?.GivenName || ""} ${
    params.row.CreatedByStaff?.Surname || ""
  }`;
}

function getFullName(params) {
  return `${getGivenName(params) || ""} ${getSurname(params) || ""}`;
}

function getGradeName(params) {
  if (JSON.parse(sessionStorage.getItem("user")).RegionID === 2) {
    return params.row.Region_Year.FullName.replace(/Year/, "Level");
  }
  return params.row.Region_Year.FullName;
}

function getRoomNumber(params) {
  return params.row.RoomNumber;
}

function getReportRender(params) {
  const user = JSON.parse(sessionStorage.getItem("user"));

  switch (user.RegionID) {
    case 2:
      return <ReportPDFQLD values={params} />;
    case 3:
      return <ReportPDFVIC values={params} />;
    case 4:
      return <ReportPDFWA values={params} />;
    case 8:
      return <ReportPDFHK values={params} />;
    default:
      return <ReportPDF values={params} />;
  }
}

const TermReportReview = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selection, setSelection] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [termReports, setTermReports] = useState([]);
  const [term, setTerm] = useState("");
  const [hideApproved, setHideApproved] = useState(false);
  const [terms, setTerms] = useState([]);
  const [mode, setMode] = useState("");
  const [viewFeedback, setViewFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [emailDialog, setEmailDialog] = useState(false);
  const [resultDialog, setResultDialog] = useState(false);
  const [publishDialog, setPublishDialog] = useState(false);
  const [bulkApproveDialog, setBulkApproveDialog] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [teacher, setTeacher] = useState("");
  const [emailStats, setEmailStats] = useState({
    sent: 0,
    noEmail: 0,
    noEmailStudents: "",
    updatedStudents: 0
  });
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [numToApprove, setNumToApprove] = useState(0);
  const [confirmApproveDialog, setConfirmApproveDialog] = useState(false);
  const [searchTeachers, setSearchTeachers] = useState([]);
  const [searchTeacher, setSearchTeacher] = useState(null);
  const [searchStudents, setSearchStudents] = useState([]);
  const [searchStudent, setSearchStudent] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState("");
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState("");
  const [status, setStatus] = useState("");
  const [numApproved, setNumApproved] = useState(0);
  const [termError, setTermError] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [roomNumber, setRoomNumber] = useState("");

  const handleGridSelect = (newSelection) => {
    setSelection(newSelection.data);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChangeTerm = (event) => {
    setTermError(false);
    setTerm(event.target.value);
  };

  const handleChangeTeacher = (event) => {
    setTeacher(event.target.value);
  };

  const handleChangeSearchTeacher = (event, value) => {
    setSearchTeacher(value);
  };

  const handleChangeSearchStudent = (event, value) => {
    setSearchStudent(value);
  };

  const handleChangeSubject = (event) => {
    setSubject(event.target.value);
  };

  const handleChangeGrade = (event) => {
    setGrade(event.target.value);
  };

  const handleStateChange = (e) => setStatus(e.target.value);

  const populateTerms = async () => {
    const response = await API.get("/terms");
    return response.data;
  };

  const populateTeachers = async () => {
    const response = await API.get("/staffs", {
      params: { Type: "Review" },
    });
    return response.data;
  };

  const populateStudents = async () => {
    const response = await API.get(
      `/students/${JSON.parse(sessionStorage.getItem("user"))?.CentreIDs[0]}`
    );
    return response.data;
  };

  const populateSubjects = async () => {
    const response = await API.get("/subjects");
    return response.data;
  };

  const populateGrades = async () => {
    const response = await API.get("/years");
    return response.data;
  };

  const handleSearch = async () => {
    const user = JSON.parse(sessionStorage.getItem("user"));

    if (!term) {
      setTermError(true);
    } else {
      let currTerm = term?.Name;
      if (user.RegionID === 8) {
        currTerm = currTerm.replace(/ \(HK\)/, "");
      }
      setSearchLoading(true);
      const response = await API.get("/termreports", {
        params: {
          TermID: term?.TermID,
          TermName: currTerm,
          hideApproved,
          StaffID: searchTeacher?.StaffID,
          StudentID: searchStudent?.StudentID,
          Subject: subject,
          Grade: grade.Region_yearID,
          RoomNumber: roomNumber,
          Status: status,
        },
      });
      let termReportData = response.data;

      if (termReportData.length > 0) {
        termReportData.forEach((item, i) => {
          item.id = i + 1;
        });
        setTermReports(response.data);
        setSearchLoading(false);
      } else {
        enqueueSnackbar(
          "There's no student report matchs the search criteria.",
          {
            variant: "warning",
          }
        );
        setSearchLoading(false);
        setTermReports([]);
      }
    }
  };

  const handleApproveUI = () => {
    handleSearch();
  };

  const handleRejectUI = (termReport) => {
    const findTermReport = termReports.find(
      (t) => t.Term_reportID === termReport.Term_reportID
    );
    findTermReport.ReportState = 4;
    findTermReport.Term_Report_State = { StateID: 4, StateName: "Rejected" };
  };

  const deleteReportUI = (id) => {
    const newTermReports = termReports.filter((t) => t.id !== id);
    setTermReports(newTermReports);
  };

  const handleBulkApproveOpen = () => {
    let reportTeachers = termReports.map((t) => ({
      StaffID: t.CreatedByStaff.StaffID,
      GivenName: t.CreatedByStaff.GivenName,
      Surname: t.CreatedByStaff.Surname,
    }));
    reportTeachers = reportTeachers.filter(
      (teacher, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.StaffID === teacher.StaffID &&
            t.GivenName === teacher.GivenName &&
            t.Surname === teacher.Surname
        )
    );

    setTeachers(
      reportTeachers.map((t) => (
        <MenuItem key={t.StaffID} value={t}>
          {t.GivenName} {t.Surname}
        </MenuItem>
      ))
    );

    setBulkApproveDialog(true);
  };

  const confirmBulkApprove = () => {
    const reportsToApprove = termReports.filter(
      (tr) => tr.CreatedBy === teacher.StaffID && tr.ReportState === 2
    );
    setNumToApprove(reportsToApprove.length);
    setConfirmApproveDialog(true);
  };

  const handleBulkApprove = async () => {
    setLoading(true);

    const reportsToApprove = termReports.filter(
      (tr) => tr.CreatedBy === teacher.StaffID && tr.ReportState === 2
    );
    const reportIDs = reportsToApprove.map((tr) => tr.Term_reportID);
    const response = await API.put("/termreports/approve", {
      Term_reportIDs: reportIDs,
    });

    if (response) {
      enqueueSnackbar("Reports has been successfully approved", {
        variant: "success",
      });
      handleApproveUI();
      handleBulkApproveClose();
      setConfirmApproveDialog(false);
    }
    setLoading(false);
  };

  const handleFeedback = () => {
    setViewFeedback(true);
  };

  const handleFeedbackClose = () => {
    setViewFeedback(false);
  };

  const handleEmailOpen = () => {
    setEmailDialog(true);
  };

  const handleEmailClose = () => {
    setEmailDialog(false);
  };

  const handleResultClose = () => {
    setResultDialog(false);
  };

  const handleBulkApproveClose = () => {
    setBulkApproveDialog(false);
  };

  const handlePublish = async () => {
    setLoading(true);
    const reportsToRepublish = termReports.filter(
      (tr) =>
        tr.ReportState === 3 || tr.ReportState === 5 || tr.ReportState === 6
    );

    const reportData = reportsToRepublish.map((tr) => ({
      Term_reportID: tr.Term_reportID,
      TermName: tr.Term.Name,
      CentreName: tr.Centre.Name,
      Subject: tr.Subject,
      StudentID: tr.StudentID,
    }));

    const reports = await Promise.all(
      reportsToRepublish.map(async (r, i) => {
        const newBlob = await pdf(getReportRender(r)).toBlob();
        const newFile = new File([newBlob], `${r.Student.StudentID}.pdf`, {
          type: "application/pdf",
          lastModified: Date.now(),
        });
        return newFile;
      })
    );

    let formData = new FormData();

    for (let i = 0; i < reports.length; i++) {
      formData.append("files", reports[i]);
      formData.append("reportData", JSON.stringify(reportData[i]));
    }

    const responses = await API.post("/termreports/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (responses && responses.status === 200) {
      const reportsToPublish = termReports.filter((tr) => tr.ReportState === 3);
      const reportIDs = reportsToPublish.map((tr) => tr.Term_reportID);

      const { success, error } = responses.data;

      if (success.length === reportsToRepublish.length) {
        if (reportsToPublish.length > 0) {
          const publishStatusResponse = await API.put("/termreports/publish", {
            Term_reportIDs: reportIDs,
          });

          if (publishStatusResponse && publishStatusResponse.status === 200) {
            enqueueSnackbar(
              `Completed Creation of ${reportsToRepublish.length} Reports`,
              { variant: "success" }
            );
            handleApproveUI();
          }
        } else {
          enqueueSnackbar(
            `Completed Creation of ${reportsToRepublish.length} Reports`,
            {
              variant: "success",
            }
          );
          handleApproveUI();
        }
      } else if (
        0 < success.length &&
        success.length < reportsToRepublish.length
      ) {
        enqueueSnackbar(
          `Partial Completion – Successfully Created ${success.length} Reports and Failed to Create ${error.length} Reports, Please take a page image and report the problem to IT team for investigation.`,
          { variant: "warning" }
        );

        const filteredApprovedReports = success.filter((re) =>
          reportIDs.includes(re.reportData.Term_reportID)
        );

        if (filteredApprovedReports.length > 0) {
          const filteredApprovedReportIDs = filteredApprovedReports.map(
            (re) => re.reportData.Term_reportID
          );
          await API.put("/termreports/publish", {
            Term_reportIDs: filteredApprovedReportIDs,
          });
          handleApproveUI();
        }
      } else {
        enqueueSnackbar(
          `Failed to Create ${error.length} Reports, Please take a page image and report the problem to IT team for investigation.`,
          {
            variant: "error",
          }
        );
        handleApproveUI();
      }
    } else {
      enqueueSnackbar("Reports publishing failed, please try again", {
        variant: "error",
      });
      handleApproveUI();
    }
    setLoading(false);
    setPublishDialog(false);
  };

  const handleEmail = async () => {
    const toSend = termReports.filter((tr) => tr.ReportState === 5);

    let noEmail = toSend.filter((ts) => ts.Student.Email === "");
    const hasEmail = toSend.filter((ts) => ts.Student.Email !== "");
    const emails = hasEmail.map((he) => ({
      email: he.Student.Email,
      StudentID: he.Student.StudentID,
    }));
    const termreportIDs = hasEmail.map((he) => he.Term_reportID);
    setEmailDialog(false);
    setLoading(true);
    const response = await API.post("/students/email", { term, emails })
      .then(async (response) => {
        // const response2 = await API.put("/termreports/update/email", {
        //   termreports: termreportIDs,
        // });
        if (response.data.emails?.length > 0) {
          noEmail.push(...emails.filter(e => !response.data.emails.contains(e.email)));
        }
        let noEmailStudents = noEmail.map(
          (ne) =>
            `${ne.Student.GivenName} ${ne.Student.Surname} (${ne.Student.StudentID})`
        );
        let newSet = new Set(noEmailStudents);
        noEmailStudents = [...newSet];

        setEmailStats({
          sent: response.data.emails?.length,
          noEmail: noEmailStudents.length,
          noEmailStudents: noEmailStudents.join(", "),
          updatedStudents: response.data.updatedStudents || 0
        });
        setResultDialog(true);
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("No new unique emails in the list.", {
          variant: "error",
        });
      });
    setLoading(false);
  };

  function renderReportDialog() {
    switch (JSON.parse(sessionStorage.getItem("user")).RegionID) {
      case 2:
        return (
          <TermReportQLD
            selection={selection}
            handleClose={handleClose}
            handleApproveUI={handleApproveUI}
            handleRejectUI={handleRejectUI}
            mode={mode}
          />
        );
      case 3:
        return (
          <TermReportVIC
            selection={selection}
            handleClose={handleClose}
            handleApproveUI={handleApproveUI}
            handleRejectUI={handleRejectUI}
            mode={mode}
          />
        );
      case 4:
        return (
          <TermReportWA
            selection={selection}
            handleClose={handleClose}
            handleApproveUI={handleApproveUI}
            handleRejectUI={handleRejectUI}
            mode={mode}
          />
        );
      default:
        return (
          <TermReportNSW
            selection={selection}
            handleClose={handleClose}
            handleApproveUI={handleApproveUI}
            handleRejectUI={handleRejectUI}
            mode={mode}
          />
        );
    }
  }

  const columns = [
    { field: "id", hide: true },
    {
      field: "Actions",
      sortable: false,
      width:
        sessionStorage.getItem("role") === "Campus Director" ||
        sessionStorage.getItem("role") === "State Director"
          ? 160
          : 120,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (
          sessionStorage.getItem("role") === "Campus Director" ||
          sessionStorage.getItem("role") === "State Director"
        ) {
          return (
            <>
              {/* <IconButton
                color="primary"
                onClick={() => {
                  setMode("review");
                  setOpenDialog(true);
                }}
              >
                <RateReviewIcon />
              </IconButton> */}
              <IconButton
                disabled={
                  params?.row?.Term_Report_State?.StateID === 1 ||
                  params?.row?.Term_Report_State?.StateID === 4 ||
                  searchLoading ||
                  loading
                }
                color="primary"
                onClick={() => {
                  setMode("edit");
                  setOpenDialog(true);
                }}
              >
                <EditIcon />
              </IconButton>
              {/* {ready && (
                <PDFDownloadLink
                  document={getReportRender(params.row)}
                  fileName={`${params.row.Student.GivenName} ${params.row.Student.Surname} ${params.row.Subject}.pdf`}
                >
                  {({ blob, url, loading, error }) => {
                    const newFile = new File([blob], "report.pdf", {
                      type: "application/pdf",
                      lastModified: Date.now(),
                    });
                    if (!loading) {
                      return (
                        <IconButton color="primary">
                          <PictureAsPdfIcon />
                        </IconButton>
                      );
                    }
                  }}
                </PDFDownloadLink>
              )} */}

              <IconButton
                color="primary"
                disabled={
                  params?.row?.Term_Report_State?.StateID === 1 ||
                  searchLoading ||
                  loading
                }
                onClick={async () => {
                  const newBlob = await pdf(
                    getReportRender(params.row)
                  ).toBlob();
                  const newFile = new File(
                    [newBlob],
                    `${params.row.Student.GivenName} ${params.row.Student.Surname} ${params.row.Subject}.pdf`,
                    {
                      type: "application/pdf",
                      lastModified: Date.now(),
                    }
                  );
                  saveAs(newFile);
                }}
              >
                <PictureAsPdfIcon />
              </IconButton>

              <TermReportDelete
                deleteReportUI={deleteReportUI}
                params={params}
              />
            </>
          );
        }
        // else if (sessionStorage.getItem("role") === "Admin") {
        //   return (
        //     <>
        //       {/* <IconButton
        //         color="primary"
        //         onClick={() => {
        //           setMode("review");
        //           setOpenDialog(true);
        //         }}
        //       >
        //         <RateReviewIcon />
        //       </IconButton> */}
        //       <IconButton
        //         color="primary"
        //         onClick={() => {
        //           setMode("edit");
        //           setOpenDialog(true);
        //         }}
        //       >
        //         <EditIcon />
        //       </IconButton>
        //       <TermReportDelete
        //         params={params}
        //         deleteReportUI={deleteReportUI}
        //       />
        //     </>
        //   );
        // }
        else if (
          params?.row?.Term_Report_State?.StateID === 1 ||
          params?.row?.Term_Report_State?.StateID === 2 ||
          params?.row?.Term_Report_State?.StateID === 4 ||
          params?.row?.Term_Report_State?.StateID === 3 ||
          params?.row?.Term_Report_State?.StateID === 5 ||
          params?.row?.Term_Report_State?.StateID === 6
        ) {
          return (
            <>
              <IconButton
                // disabled={params?.row?.Term_Report_State?.StateID === 2}
                color="primary"
                onClick={() => {
                  setMode("edit");
                  setOpenDialog(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <TermReportDelete
                params={params}
                deleteReportUI={deleteReportUI}
              />
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: "Feedback",
      sortable: false,
      width: 150,
      disableColumnMenu: true,
      hide:
        sessionStorage.getItem("role") === "Campus Director" ||
        sessionStorage.getItem("role") === "State Director",
      renderCell: (params) => {
        return (
          <>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleFeedback}
              disabled={!!!params.value}
            >
              View Feedback
            </Button>
            <Dialog
              open={viewFeedback}
              onClose={handleFeedbackClose}
              className={classes.feedbackDialog}
            >
              <DialogTitle disableTypography>
                <Typography variant="h6">
                  Reason Report was Not accepted:
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1">{feedback}</Typography>
              </DialogContent>
            </Dialog>
          </>
        );
      },
    },
    { field: "StudentID", disableColumnMenu: true, width: 120 },
    {
      field: "FullName",
      headerName: "Full Name",
      width: 200,
      valueGetter: getFullName,
      disableColumnMenu: true,
    },
    {
      field: "Centre",
      headerName: "Campus",
      width: 150,
      valueGetter: getCentreName,
      disableColumnMenu: true,
      hide: sessionStorage.getItem("role") === "Campus Director",
    },
    { field: "Subject", width: 550, disableColumnMenu: true },
    {
      field: "RegionYear",
      headerName: "Grade",
      width: 150,
      valueGetter: getGradeName,
      disableColumnMenu: true,
    },
    {
      field: "RoomNumber",
      headerName: "Room Number",
      width: 150,
      valueGetter: getRoomNumber,
      disableColumnMenu: true,
    },
    {
      field: "reportState",
      headerName: "Status",
      width: 150,
      disableColumnMenu: true,
      // hide: !(
      //   sessionStorage.getItem("role") === "Campus Director" ||
      //   sessionStorage.getItem("role") === "State Director"
      // ),
      // renderCell: (params) => {
      //   console.log(params)
      //   return params.row.Term_Report_State?.StateName.toString();
      // },
      valueGetter: getReportStateName,
    },
    {
      field: "CreatedBy",
      header: "Created By",
      sortable: true,
      width: 170,
      disableColumnMenu: true,
      hide: !(
        sessionStorage.getItem("role") === "Campus Director" ||
        sessionStorage.getItem("role") === "State Director"
      ),
      valueGetter: getCreatedBy,
    },
  ];

  useEffect(async () => {
    populateTerms().then((termData) => {
      setTerms(
        termData.map((t) => (
          <MenuItem key={t.TermID} value={t}>
            {t.Name.replace(/ \(HK\)/, "")}
          </MenuItem>
        ))
      );
    });
    if (
      sessionStorage.getItem("role") === "Campus Director" ||
      sessionStorage.getItem("role") === "State Director"
    ) {
      populateTeachers().then((teacherData) => {
        teacherData.sort((a, b) => {
          let givenNameA = a.GivenName.toUpperCase();
          let givenNameB = b.GivenName.toUpperCase();
          return givenNameA < givenNameB ? -1 : givenNameA > givenNameB ? 1 : 0;
        });

        setSearchTeachers(
          teacherData.map((t) => ({
            StaffID: t.StaffID,
            GivenName: t.GivenName,
            Surname: t.Surname,
          }))
        );
      });
      populateStudents().then((studentsData) => {
        const newStudentsDataArray = studentsData.map((s) => ({
          StudentID: s.StudentID,
          Surname: s.Surname,
          GivenName: s.GivenName,
        }));

        const noDuplicatedStudentsData = [
          ...new Set(newStudentsDataArray.map((s) => JSON.stringify(s))),
        ].map((s) => JSON.parse(s));

        setSearchStudents(noDuplicatedStudentsData);
      });
    }
    populateSubjects().then((subjectData) => {
      setSubjects(
        subjectData
          .sort((a, b) => {
            let fa = a.Name.toLowerCase(),
              fb = b.Name.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
          })
          .map((s) => (
            <MenuItem key={s.Report_subjectID} value={s.Name}>
              {s.Name}
            </MenuItem>
          ))
      );
    });
    populateGrades().then((gradeData) => {
      setGrades(
        gradeData.map((g) => (
          <MenuItem key={g.Region_yearID} value={g}>
            {JSON.parse(sessionStorage.getItem("user")).RegionID === 2
              ? g.FullName.replace(/Year/, "Level")
              : g.FullName}
          </MenuItem>
        ))
      );
    });
  }, []);

  useEffect(() => {
    setFeedback(selection.Feedback);
  }, [selection]);

  useEffect(async () => {
    if (publishDialog) {
      const reports = termReports.filter(
        (tr) =>
          tr.ReportState === 3 || tr.ReportState === 5 || tr.ReportState === 6
      );
      setNumApproved(reports.length);
    }
  }, [publishDialog]);

  useEffect(() => {
    getCurrentTerm().then((termData) => {
      setTerm(termData);
    });
  }, []);

  const handlePageSizeChange = ({ pageSize }) => setPageSize(pageSize);

  const handleInputChange = ({ target }) => {
    const re = /^[a-zA-Z ]+$/;
    if (!target.value || re.test(target.value)) {
      setSearchName(target.value);
    }
  };

  const handleNameSearch = async () => {
    if (!searchName) {
      enqueueSnackbar("You need put in a student name.", {
        variant: "warning",
      });
      return;
    }

    const user = JSON.parse(sessionStorage.getItem("user"));

    if (!term) {
      setTermError(true);
    } else {
      let currTerm = term?.Name;
      if (user.RegionID === 8) {
        currTerm = currTerm.replace(/ \(HK\)/, "");
      }
      setSearchLoading(true);

      const response = await API.get("/termreports/student", {
        params: {
          TermID: term?.TermID,
          TermName: currTerm,
          searchName,
        },
      });

      let termReportData = response.data;

      if (termReportData.length > 0) {
        termReportData.forEach((item, i) => {
          item.id = i + 1;
        });
        setTermReports(response.data);
        setSearchLoading(false);
      } else {
        enqueueSnackbar(
          "There's no student report matchs the search criteria.",
          {
            variant: "warning",
          }
        );
        setSearchLoading(false);
        setTermReports([]);
      }
    }
  };

  return (
    <Container className={classes.container} maxWidth={false}>
      <Paper elevation={1} className={classes.paper}>
        <div className={classes.searchContainer}>
          <FormGroup className={classes.formGroup} row>
            <FormControl
              className={classes.formControl}
              required
              error={termError}
            >
              <InputLabel id="term-label">Term</InputLabel>
              <Select
                labelId="term-label"
                id="term-select"
                value={term}
                onChange={handleChangeTerm}
                renderValue={(value) => value?.Name}
                required
              >
                {terms}
              </Select>
              {termError && <FormHelperText>Term Required</FormHelperText>}
            </FormControl>
            {sessionStorage.getItem("role") === "Campus Director" && (
              <FormControl className={classes.formControl}>
                <Autocomplete
                  id="teacher-select"
                  options={searchTeachers}
                  autoComplete
                  includeInputInList
                  getOptionLabel={(option) =>
                    `${option?.GivenName} ${option?.Surname}`
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Teacher" />
                  )}
                  style={{ width: 150 }}
                  onChange={handleChangeSearchTeacher}
                  value={searchTeacher}
                />
              </FormControl>
            )}
            {/* {sessionStorage.getItem("role") === "Campus Director" && (
              <FormControl className={classes.formControl}>
                <Autocomplete
                  id="student-select"
                  options={searchStudents}
                  autoComplete
                  includeInputInList
                  getOptionLabel={(option) =>
                    `${option?.GivenName} ${option?.Surname}`
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Student" />
                  )}
                  style={{ minWidth: 200 }}
                  onChange={handleChangeSearchStudent}
                  value={searchStudent}
                />
              </FormControl>
            )} */}
            <FormControl className={classes.formControl}>
              <InputLabel id="subject-label">Subject</InputLabel>
              <Select
                id="subject-select"
                labelId="subject-label"
                value={subject}
                onChange={handleChangeSubject}
              >
                <MenuItem value="">None</MenuItem>
                {subjects}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="grade-label">Grade</InputLabel>
              <Select
                labelId="grade-label"
                id="grade-select"
                value={grade}
                onChange={handleChangeGrade}
                renderValue={(value) =>
                  JSON.parse(sessionStorage.getItem("user")).RegionID === 2
                    ? value?.FullName.replace(/Year/, "Level")
                    : value?.FullName
                }
              >
                <MenuItem value="">None</MenuItem>
                {grades}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="status">Status</InputLabel>
              <Select
                labelId="status"
                id="status"
                value={status}
                onChange={handleStateChange}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="1">In Progress</MenuItem>
                <MenuItem value="2">Pending Review</MenuItem>
                <MenuItem value="3">Approved</MenuItem>
                <MenuItem value="4">Rejected</MenuItem>
                <MenuItem value="5">Published</MenuItem>
                <MenuItem value="6">Email Sent</MenuItem>
              </Select>
            </FormControl>
            {JSON.parse(sessionStorage.getItem("user")).RegionID === 4 && (
              <FormControl className={classes.formControl}>
                <TextField
                  id="roomNumber"
                  name="roomNumber"
                  label="Room Number"
                  variant="standard"
                  value={roomNumber}
                  onChange={(e) => setRoomNumber(e.target.value)}
                />
              </FormControl>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideApproved}
                  onChange={() => {
                    setHideApproved(!hideApproved);
                  }}
                  name="hideApprovedCheck"
                  disabled={Boolean(status)}
                />
              }
              label="Hide approved reports"
            />
            <FormControl className={classes.formControl}>
              <Button
                startIcon={<SearchIcon />}
                variant="contained"
                onClick={handleSearch}
                color="primary"
                disabled={searchLoading || loading}
              >
                Search
              </Button>
            </FormControl>
          </FormGroup>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              value={searchName}
              label="Search by student name"
              variant="outlined"
              size="small"
              required
              onChange={handleInputChange}
              style={{ marginRight: "10px" }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              onClick={handleNameSearch}
              disabled={!searchName || searchLoading || loading}
            >
              Search
            </Button>
          </div>
        </div>
        {termReports.length > 0 &&
          sessionStorage.getItem("role") === "Campus Director" && (
            <div className={classes.buttonGroup}>
              <Button
                startIcon={<CheckCircleIcon />}
                className={classes.button1}
                variant="contained"
                onClick={handleBulkApproveOpen}
                color="primary"
                disabled={loading}
              >
                Bulk Approve Reports
              </Button>
              <Button
                startIcon={<PublishIcon />}
                className={classes.button1}
                variant="contained"
                onClick={() => setPublishDialog(true)}
                color="primary"
                disabled={loading}
              >
                Publish Reports to Students
              </Button>
              <Button
                startIcon={<EmailIcon />}
                variant="contained"
                onClick={handleEmailOpen}
                color="primary"
                disabled={loading}
              >
                Email Approved Reports
              </Button>
            </div>
          )}
        <Dialog
          maxWidth="md"
          disableBackdropClick
          open={openDialog}
          onClose={handleClose}
        >
          {renderReportDialog()}
        </Dialog>
        <DataGrid
          autoHeight
          rows={termReports}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onRowSelected={(newSelection) => {
            handleGridSelect(newSelection);
          }}
          loading={searchLoading}
        />
        <Dialog
          onClose={handleEmailClose}
          open={emailDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Send Emails</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to send unsent emails to students?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEmail} color="primary" disabled={loading}>
              Send
            </Button>
            <Button onClick={handleEmailClose} color="secondary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={handleResultClose}
          open={resultDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Email Stats</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have sent {emailStats.sent} emails. There {emailStats.noEmail !== 1 ? 'are ' : 'is '}
              {emailStats.noEmail} student{emailStats.noEmail !== 1 ? 's' : ''} with no email listed.{" "}
              {emailStats.noEmailStudents &&
                `The students who do not have an email are ${emailStats.noEmailStudents}.`}{" "}
              {emailStats.updatedStudents > 0 &&
                `There are ${emailStats.updatedStudents} student${emailStats.updatedStudents !== 1 ? 's' : ''} with an email sent this term and have had their remaining reports updated.`
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResultClose} color="secondary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={handleBulkApproveClose}
          open={bulkApproveDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Approve Teacher's Reports</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select Teacher to Approve all Reports:
            </DialogContentText>
            <InputLabel id="teacher-label">Teacher</InputLabel>
            <Select
              labelId="teacher-label"
              id="teacher-select"
              value={teacher}
              onChange={handleChangeTeacher}
              renderValue={(value) => `${value?.GivenName} ${value?.Surname}`}
              required
            >
              {teachers}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={confirmBulkApprove} color="primary" autoFocus>
              Approve
            </Button>
            <Button
              onClick={handleBulkApproveClose}
              color="secondary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => {
            setConfirmApproveDialog(false);
          }}
          open={confirmApproveDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Approve Reports</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to approve {numToApprove} reports?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleBulkApprove}
              color="primary"
              disabled={loading}
            >
              Approve
            </Button>
            <Button
              onClick={() => {
                setConfirmApproveDialog(false);
              }}
              color="secondary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => setPublishDialog(false)}
          open={publishDialog}
          disableBackdropClick
          fullWidth
        >
          <DialogTitle>Publish Reports</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {loading
                ? "Uploading Reports...Please do not refresh or close your browser."
                : `Are you sure you want to publish ${numApproved} reports?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div style={{ position: "relative" }}>
              <Button
                onClick={handlePublish}
                color="primary"
                disabled={loading}
              >
                {loading ? "Publishing..." : "Publish"}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </div>

            <Button
              onClick={() => setPublishDialog(false)}
              color="secondary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default TermReportReview;
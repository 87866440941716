import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CreateIcon from '@material-ui/icons/Create';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Link } from 'react-router-dom';

export const mainListItems = (role) => {
  const canCreate =
    role === "Admin" || role === "State Director" || role === "Campus Director";
  return (
    <div>
      {role !== "Admin" && (
        <>
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button component={Link} to="/termreport">
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Student Reports" />
          </ListItem>
        </>
      )}

      {canCreate && (
        <ListItem button component={Link} to="/usermanagement">
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="User Management" />
        </ListItem>
      )}

      {false && (
        <>
          <ListItem button component={Link} to="/updatedetails">
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Update Details" />
          </ListItem>
          <ListItem button component={Link} to="/teacheravailability">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Availability Form" />
          </ListItem>
          <ListItem button component={Link} to="/homework">
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText primary="Homework" />
          </ListItem>
        </>
      )}
    </div>
  );
};
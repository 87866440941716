import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { Legend } from "@devexpress/dx-react-chart-material-ui";
import { withStyles } from "@material-ui/core/styles";
import { API } from "../api";
import axios from "axios";

const initialReportsData = [
  {
    region: "NSW",
    submitted: 100,
    toFix: 20,
    approved: 80,
    sent: 75,
  },
  {
    region: "QLD",
    submitted: 81,
    toFix: 21,
    approved: 60,
    sent: 55,
  },
  {
    region: "VIC",
    submitted: 73,
    toFix: 11,
    approved: 62,
    sent: 60,
  },
  {
    region: "WA",
    submitted: 19,
    toFix: 2,
    approved: 15,
    sent: 13,
  },
  {
    region: "NZ",
    submitted: 54,
    toFix: 9,
    approved: 45,
    sent: 40,
  },
  {
    region: "ACT",
    submitted: 28,
    toFix: 3,
    approved: 25,
    sent: 23,
  },
  {
    region: "SA",
    submitted: 16,
    toFix: 10,
    approved: 15,
    sent: 14,
  },
  {
    region: "HK",
    submitted: 66,
    toFix: 12,
    approved: 54,
    sent: 50,
  },
];

const pieData = [
  { region: "NSW", reportsSent: 75 },
  { region: "QLD", reportsSent: 55 },
  { region: "VIC", reportsSent: 60 },
  { region: "WA", reportsSent: 13 },
  { region: "NZ", reportsSent: 40 },
  { region: "ACT", reportsSent: 23 },
  { region: "SA", reportsSent: 14 },
  { region: "HK", reportsSent: 50 },
];

const lineChartData = [
  { date: "21-07-2021", nsw: 15, vic: 12, hk: 11, nz: 9, wa: 17 },
  { date: "22-07-2021", nsw: 14, vic: 11, hk: 10, nz: 12, wa: 16 },
  { date: "23-07-2021", nsw: 10, vic: 10, hk: 7, nz: 11, wa: 8 },
  { date: "24-07-2021", nsw: 11, vic: 6, hk: 12, nz: 7, wa: 9 },
  { date: "25-07-2021", nsw: 22, vic: 18, hk: 13, nz: 8, wa: 12 },
  { date: "26-07-2021", nsw: 18, vic: 19, hk: 7, nz: 13, wa: 10 },
  { date: "27-07-2021", nsw: 20, vic: 11, hk: 9, nz: 14, wa: 12 },
];

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "93vh",
    overflow: "auto",
    padding: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  reportTitle: {
    // marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  reportNumber: {
    flexGrow: 1,
    // marginBottom: theme.spacing(3),
  },
  reportRow: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const legendStyles = () => ({
  root: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
  },
});
const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const Root = withStyles(legendStyles, { name: "LegendRoot" })(legendRootBase);
const legendLabelStyles = () => ({
  label: {
    whiteSpace: "nowrap",
  },
});

const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: "LegendLabel" })(
  legendLabelBase
);

const Dashboard = () => {
  const classes = useStyles();

  const [reportsData, setReportsData] = useState({});
  const role = sessionStorage.getItem("role");

  useEffect(async () => {
    const newData = {
      totalCreated: 0,
      totalWorkInProgress: 0,
      totalSubmitted: 0,
      totalApproved: 0,
      totalRejected: 0,
      totalPublished: 0,
      totalEmailed: 0,
      lastUpdate: moment().format("Do MMM YYYY"),
    };

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    API.get("/currentterm").then((term) => {
      const user = JSON.parse(sessionStorage.getItem("user"));
      let newTerm = term?.data?.Name;
      if (user.RegionID === 8) {
        newTerm = newTerm.replace(/ \(HK\)/, "");
      }
      API.get(
        "/termreports/count",
        {
          params: { TermID: term?.data?.TermID },
        },
        { cancelToken: source.token }
      )
        .then(({ data }) => {
          newData.totalCreated = data.length;
          data.forEach((report) => {
            if (report.ReportState === 1) newData.totalWorkInProgress++;
            if (report.ReportState === 2) newData.totalSubmitted++;
            if (report.ReportState === 3) newData.totalApproved++;
            if (report.ReportState === 4) newData.totalRejected++;
            if (report.ReportState === 5) newData.totalPublished++;
            if (report.ReportState === 6) newData.totalEmailed++;
          });
          return newData;
        })
        .then((data) => setReportsData(data));
    });

    return () => {
      source.cancel("request canceled");
    };
  }, []);

  return (
    <main className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Paper className={classes.fixedHeightPaper}>
            <div className={classes.reportRow}>
              <Typography
                component="h6"
                variant="h5"
                color="textSecondary"
                className={classes.reportTitle}
              >
                Total Created Reports:
              </Typography>
              <Typography
                component="p"
                variant="h4"
                className={classes.reportNumber}
              >
                {reportsData?.totalCreated}
              </Typography>
            </div>
            <div className={classes.reportRow}>
              <Typography
                component="h6"
                variant="h5"
                color="textSecondary"
                className={classes.reportTitle}
              >
                Total Work-In-Progress Reports:
              </Typography>
              <Typography
                component="p"
                variant="h4"
                className={classes.reportNumber}
              >
                {reportsData?.totalWorkInProgress}
              </Typography>
            </div>
            <div className={classes.reportRow}>
              <Typography
                component="h6"
                variant="h5"
                color="textSecondary"
                className={classes.reportTitle}
              >
                Total Submitted Reports:
              </Typography>
              <Typography
                component="p"
                variant="h4"
                className={classes.reportNumber}
              >
                {reportsData?.totalSubmitted}
              </Typography>
            </div>
            <div className={classes.reportRow}>
              <Typography
                component="h6"
                variant="h5"
                color="textSecondary"
                className={classes.reportTitle}
              >
                Total Approved Reports:
              </Typography>
              <Typography
                component="p"
                variant="h4"
                className={classes.reportNumber}
              >
                {reportsData?.totalApproved}
              </Typography>
            </div>
            <div className={classes.reportRow}>
              <Typography
                component="h6"
                variant="h5"
                color="textSecondary"
                className={classes.reportTitle}
              >
                Total Rejected Reports:
              </Typography>
              <Typography
                component="p"
                variant="h4"
                className={classes.reportNumber}
              >
                {reportsData?.totalRejected}
              </Typography>
            </div>
            <div className={classes.reportRow}>
              <Typography
                component="h6"
                variant="h5"
                color="textSecondary"
                className={classes.reportTitle}
              >
                Total Published Reports:
              </Typography>
              <Typography
                component="p"
                variant="h4"
                className={classes.reportNumber}
              >
                {reportsData?.totalPublished}
              </Typography>
            </div>
            <div className={classes.reportRow}>
              <Typography
                component="h6"
                variant="h5"
                color="textSecondary"
                className={classes.reportTitle}
              >
                Total Emailed Reports:
              </Typography>
              <Typography
                component="p"
                variant="h4"
                className={classes.reportNumber}
              >
                {reportsData?.totalEmailed}
              </Typography>
            </div>
            <Typography variant="subtitle1" color="textSecondary">
              Last update on {reportsData?.lastUpdate}
            </Typography>
          </Paper>
        </Grid>
        {/* {false &&
          (role === "State Teacher" ||
            role === "Teacher" ||
            role === "Limited State Teacher" ||
            role === "Admin") && (
            <Grid item xs={12} md={5}>
              <Paper>
                <Chart data={initialReportsData} height={450}>
                  <ArgumentAxis />
                  <ValueAxis />
                  <BarSeries
                    name="Submitted"
                    valueField="submitted"
                    argumentField="region"
                    color="#3185FC"
                  />
                  <BarSeries
                    name="Approved"
                    valueField="approved"
                    argumentField="region"
                    color="#00C49A"
                  />
                  <BarSeries
                    name="To Fix"
                    valueField="toFix"
                    argumentField="region"
                    color="#F56476"
                  />
                  <Legend
                    position="bottom"
                    rootComponent={Root}
                    labelComponent={Label}
                  />
                  <Title text="Student Reports" />
                  <Stack />
                  <EventTracker />
                  <Tooltip />
                  <Animation />
                </Chart>
              </Paper>
            </Grid>
          )}

        {role === "Admin" && (
          <Grid item xs={12} md={5}>
            <Paper>
              <Chart data={initialReportsData} height={450}>
                <ArgumentScale factory={scaleBand} />
                <ArgumentAxis />
                <ValueAxis />
                <BarSeries
                  name="Approved"
                  valueField="approved"
                  argumentField="region"
                  color="#00C49A"
                />
                <BarSeries
                  name="Sent"
                  valueField="sent"
                  argumentField="region"
                  color="#7C99B4"
                />
                <BarSeries
                  name="To Review"
                  valueField="toFix"
                  argumentField="region"
                  color="#F56476"
                />
                <Legend
                  position="bottom"
                  rootComponent={Root}
                  labelComponent={Label}
                />
                <Title text="Student Reports" />
                <Stack />
                <EventTracker />
                <Tooltip />
                <Animation />
              </Chart>
            </Paper>
          </Grid>
        )}

        {role === "Admin" && (
          <Grid item xs={12} md={8}>
            <Paper>
              <Chart data={lineChartData} height={450}>
                <ArgumentAxis />
                <ValueAxis />
                <LineSeries name="NSW" valueField="nsw" argumentField="date" />
                <LineSeries name="VIC" valueField="vic" argumentField="date" />
                <LineSeries name="HK" valueField="hk" argumentField="date" />
                <LineSeries name="NZ" valueField="nz" argumentField="date" />
                <LineSeries name="WA" valueField="wa" argumentField="date" />
                <EventTracker />
                <Tooltip />
                <Legend
                  position="bottom"
                  rootComponent={Root}
                  labelComponent={Label}
                />
                <Animation />
                <Title text="Last 7 days Submitted Reports" />
              </Chart>
            </Paper>
          </Grid>
        )}

        {role === "Admin" && (
          <Grid item xs={12} md={4}>
            <Paper>
              <Chart data={pieData} height={450}>
                <PieSeries
                  valueField="reportsSent"
                  argumentField="region"
                  outerRadius="0.8"
                />
                <Title text="Total Sent Reports for each region" />
                <EventTracker />
                <Tooltip />
                <Legend />
                <Animation />
              </Chart>
            </Paper>
          </Grid>
        )} */}
      </Grid>
    </main>
  );
};

export default Dashboard;

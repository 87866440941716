import React, { useContext } from 'react';
import logo from "../assets/NSCC_logo.png";
import clsx from 'clsx';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import UserDialog from './UserDialog';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AuthenticatedContext from './AuthenticatedContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    marginRight: theme.spacing(3),
    height: "60px",
    maxWidth: "100px",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
}))

const Header = props => {
  const classes = useStyles();

  const authContext = useContext(AuthenticatedContext);

  const maintenance = true;

  const drawerButton = () => {
    if (sessionStorage.getItem('auth') === 'true' || authContext.userAuthenticated) {
      return (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleDrawerOpenProps}
          className={clsx(classes.menuButton, props.drawerOpenProps && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
      )
    }
  }

  const showUserDialog = () => {
    if (
      sessionStorage.getItem("auth") === "true" ||
      authContext.userAuthenticated
    ) {
      return <UserDialog />;
    }
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          props.drawerOpenProps && classes.appBarShift
        )}
      >
        <Toolbar className={classes.toolbar}>
          {drawerButton()}
          <img src={logo} alt="NSCC Logo" className={classes.logo} />
          <Typography variant="h6" className={classes.title}>
            NSCC{" " + (authContext.authenticatedRole || "")} Portal
          </Typography>
          {showUserDialog()}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Badge, IconButton, makeStyles, MenuItem, Popover } from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import AuthenticatedContext from './AuthenticatedContext';

import '../index.css';
import { indigo } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  indigo: {
    color: '#fff',
    backgroundColor: indigo[500],
    width: "100%",
    paddingTop: "100%"
  },
  root: {
    width: "15vw",
    height: "20vh"
  }
}));

const UserDialog = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  const authContext = useContext(AuthenticatedContext);

  const handleLogout = () => {
    setAnchorEl(null);
    sessionStorage.setItem("auth", "false");
    sessionStorage.setItem("role", "");
    sessionStorage.setItem("token", null);
    authContext.logoutSuccess();
  }

  /*
  <IconButton color="inherit">
        <Badge badgeContent={4} color="secondary">
        <NotificationsIcon />
        </Badge>
      </IconButton>


  <Avatar className={classes.indigo}>
          <PersonIcon />
        </Avatar>
  */

  const roleName = (roleID) => {
    switch (roleID) {
      case 1:
        return "Admin";
      case 2:
        return "Teacher";
      case 3:
        return "Student";
      case 4:
        return "Campus Director";
      case 5:
        return "State Director";
      case 6:
        return "State Teacher";
      case 7:
        return "Limited State Teacher";
      default:
        return null;
    }
  }

  return (
    <div style={{ float: 'right' }}>
      
      <IconButton
        color="inherit"
        aria-controls="simple-popover"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <PersonIcon />
      </IconButton>
      <Popover
        id="simple-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.root}
      >
        <MenuItem onClick={handleLogout} component={Link} to="/">Logout</MenuItem>
      </Popover>
    </div>
    )
}

export default UserDialog;
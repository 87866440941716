import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import { TextField, Select } from "formik-material-ui";
import { Formik, Form, Field } from "formik";
import MuiTextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import RoleType from "../utils/RoleTypes";
import EditIcon from "@material-ui/icons/Edit";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PublicIcon from "@material-ui/icons/Public";
import { Autocomplete } from "formik-material-ui-lab";
import { useSnackbar } from "notistack";
import { API } from "../api";

const UserManagementEditUser = ({
  params,
  editUser,
  roles,
  campuses,
  regions,
  authenticatedRole,
}) => {
  const campusNames = params?.row?.campus?.split(", ");
  let startingCampus = campuses?.filter((c) => campusNames?.includes(c.Name));
  //startingCampus.length === 1 ? startingCampus = startingCampus.map(c => c.CentreID) : ;
  const initialValues = {
    RoleID: roles.find((role) => role.Name === params.row.role)["RoleID"],
    firstname: params.row.GivenName,
    lastname: params.row.Surname,
    shortname: params.row.ShortName,
    username: params.row.Username,
    region: regions?.find((region) => region.Name === params.row.region)
      ? regions?.find((region) => region.Name === params.row.region)["RegionID"]
      : "",
    campus: startingCampus.length === 1 ? startingCampus[0]?.CentreID : "",
    multiCampus: startingCampus.length > 1 ? startingCampus : [],
  };

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [newRoles, setNewRoles] = useState([]);
  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);

  const validationSchema = Yup.object({
    RoleID: Yup.string().required("Required"),
    firstname: Yup.string().max(45).required("Required"),
    lastname: Yup.string().max(45).required("Required"),
    shortname: Yup.string(),
  });

  useEffect(() => {
    const role = sessionStorage.getItem("role");
    switch (role) {
      case "State Director":
        setNewRoles(roles.filter((r) => r.RoleID !== 5));
        break;

      case "Campus Director":
        setNewRoles(roles.filter((r) => r.RoleID === 2));
        break;
      default:
        setNewRoles(roles);
    }
  }, []);

  const onSubmit = async ({
    lastname,
    firstname,
    shortname,
    RoleID,
    region,
    campus,
    multiCampus,
  }) => {
    const user = {
      Surname: lastname,
      GivenName: firstname,
      ShortName: shortname,
      RoleID,
      CentreIDs:
        multiCampus.length > 0
          ? multiCampus.map((c) => c.CentreID)
          : [].concat(campus),
      RegionID: region,
    };

    const response = await API.put(`/staff/${params.id}`, user)
      .then(({ data }) => {
        const editedUser = {
          id: data.StaffID,
          Surname: data.Surname || "",
          GivenName: data.GivenName || "",
          Username: data.Username || "",
          ShortName: data.ShortName || "",
          role: data.Role?.RoleName || "Customer Service Support",
          campus:
            data.Centres.length > 1
              ? data.Centres?.map((centre) => centre.Name).join(", ")
              : data.Centres[0]?.Name,
          region: data.Region.Name || "",
        };

        editUser(params.id, editedUser);
        enqueueSnackbar("User has been successfully edited", {
          variant: "success",
        });
        handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(`${error}`, {
          variant: "warning",
        });
      });
  };

  return (
    <>
      <IconButton size="small" color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValid, values, touched, errors }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="RoleID">Role</InputLabel>
                        <Field
                          component={Select}
                          name="RoleID"
                          inputProps={{ name: "RoleID", id: "RoleID" }}
                          disabled={
                            authenticatedRole === "Campus Director"
                              ? true
                              : false
                          }
                        >
                          {newRoles.map(({ RoleID, Name }) => (
                            <MenuItem key={RoleID} value={RoleID}>
                              {Name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        fullWidth
                        type="text"
                        id="firstname"
                        name="firstname"
                        label="First Name"
                        component={TextField}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        fullWidth
                        type="text"
                        id="lastname"
                        name="lastname"
                        label="Last Name"
                        component={TextField}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        type="text"
                        id="shortname"
                        name="shortname"
                        label="Short Name"
                        component={TextField}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        type="text"
                        id="username"
                        name="username"
                        label="Username"
                        component={TextField}
                        required
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="region">Region</InputLabel>
                        <Field
                          component={Select}
                          name="region"
                          disabled={
                            authenticatedRole === "Admin" ? false : true
                          }
                          startAdornment={
                            <InputAdornment position="start">
                              <HomeWorkIcon />
                            </InputAdornment>
                          }
                          inputProps={{ name: "region", id: "region" }}
                        >
                          {regions.map(({ RegionID, Name }) => (
                            <MenuItem key={RegionID} value={RegionID}>
                              {Name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>

                    {(values.RoleID === RoleType.CAMPUSDIRECTOR ||
                      values.RoleID === RoleType.TEACHER ||
                      values.RoleID === RoleType.CUSTOMERSERVICESUPPORT) && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="campus">Campus</InputLabel>
                          <Field
                            component={Select}
                            name="campus"
                            disabled={
                              authenticatedRole === "Campus Director"
                                ? true
                                : false
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <PublicIcon />
                              </InputAdornment>
                            }
                            inputProps={{ name: "campus", id: "campus" }}
                          >
                            {campuses.map(({ CentreID, Name }) => (
                              <MenuItem key={CentreID} value={CentreID}>
                                {Name}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>
                    )}

                    {values.RoleID === RoleType.LIMITEDSTATETEACHER && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Field
                            multiple
                            name="multiCampus"
                            component={Autocomplete}
                            options={campuses}
                            getOptionLabel={(option) => option.Name}
                            renderInput={(AutocompleteRenderInputParams) => (
                              <MuiTextField
                                {...AutocompleteRenderInputParams}
                                error={
                                  touched["multiCampus"] &&
                                  !!errors["multiCampus"]
                                }
                                helperText={errors["multiCampus"]}
                                label="Campuses"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item style={{ marginTop: 20, marginBottom: 10 }}>
                      <Button
                        style={{ marginRight: 20 }}
                        color="secondary"
                        type="submit"
                        disabled={!isValid || isSubmitting}
                      >
                        Submit
                      </Button>
                      <Button
                        style={{ marginRight: 20 }}
                        color="secondary"
                        type="reset"
                      >
                        Reset
                      </Button>
                      <Button color="primary" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserManagementEditUser;

import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Button, Snackbar, Link, LinearProgress, InputLabel, Container, Typography, makeStyles, Grid, Paper, FormControl, MenuItem, InputAdornment } from '@material-ui/core'
import * as Yup from 'yup'
import Alert from '@material-ui/lab/Alert'
import { TextField, Select, CheckboxWithLabel } from 'formik-material-ui'
import { TimePicker, DatePicker } from 'formik-material-ui-pickers'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import PublicIcon from '@material-ui/icons/Public';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import SchoolIcon from '@material-ui/icons/School';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: { padding: 16, maxWidth: 800 },
  title: { marginTop: 20, marginBottom: 20 },
  subTitle: { marginTop: 20, marginBottom: 20 },
  paper: { padding: 32 },
  button: { marginRight: 32 }
}))

const campuses = [
  { id: '1', name: 'Ashfield'},
  { id: '2', name: 'Bankstown'},
  { id: '3', name: 'Berala'},
  { id: '4', name: 'Beverly Hills'},
  { id: '5', name: 'Blacktown'},
]

const regions = [
  { id: '1', name: 'NSW'},
  { id: '2', name: 'QLD'},
  { id: '3', name: 'VIC'},
  { id: '4', name: 'WA'},
  { id: '5', name: 'ACT'},
  { id: '6', name: 'SA'},
  { id: '7', name: 'HK'},
]

const grades = [
  { id: '1', name: 'Year 3'},
  { id: '2', name: 'Year 4'},
  { id: '3', name: 'Year 5'},
  { id: '4', name: 'Year 6'},
  { id: '5', name: 'Year 7'},
  { id: '6', name: 'Year 8'},
  { id: '7', name: 'Year 9'},
]

/*const subjects = [
  { id: '1', name: 'English'},
  { id: '2', name: 'Maths'},
]

const level = [
  { id: '1', name: 'Primary'},
  { id: '2', name: 'Hight School'},
  { id: '3', name: 'HSC'},
]*/

const TeacherAvailability = () => {

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const history = useHistory()

  const validationSchema = Yup.object({
    // firstname: Yup.string().required('Required'),
    // lastname: Yup.string().required('Required'),
    // email: Yup.string().email('Invalid email format').required('Required'),
    // phone: Yup.number().required('Required'),
    // address: Yup.string().required('Required'),
    // select: Yup.string().required('Required'),
    // dateTime: Yup.date().required('Required').nullable()
  })

  // , channel: '', comments: '', address: '', social: {facebook: '', twitter: ''}, phoneNumbers: ['', ''], phNumbers: ['']

  const initialValues = { 
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    telephone: '',
    address: '',
    region: '',
    campus: '',
    grade: '',
    workWithChildrenNum: '',
    workWithChildrenExp: null,
    monday: false,
    startMonday: null,
    endMonday: null,
    tuesday: false,
    startTuesday: null,
    endTuesday: null,
    wednesday: false,
    startWednesday: null,
    endWednesday: null,
    thursday: false,
    startThursday: null,
    endThursday: null,
    friday: false,
    startFriday: null,
    endFriday: null,
    saturday: false,
    startSaturday: null,
    endSaturday: null,
    sunday: false,
    startSunday: null,
    endSunday: null,
  }

  const onSubmit = (values, {setSubmitting, resetForm}) => {
    setTimeout(() => {
      console.log('submit values: ', values)
      setSubmitting(false)
      setOpen(true)
      //resetForm()
      
    }, 500)

    setTimeout(() => {
      history.push('/teacher-all')
    }, 3600)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
    <Container className={classes.root}>
      
      <Typography className={classes.title} variant="h4" component="h2" align="center" gutterBottom color="textPrimary">
        Teacher Availability Record
      </Typography>
      
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      
      <Formik
        initialValues={initialValues} 
        validationSchema={validationSchema} 
        onSubmit={onSubmit}
      >
        {({isSubmitting, isValid}) => {
          return (
            <Form>
              <Paper elevation={0} className={classes.paper}>

              <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                  <Field fullWidth type='text' id='firstname' name='firstname' label="First Name" component={TextField} required InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field fullWidth type='text' id='lastname' name='lastname' label="Last Name" component={TextField} required InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={6}>
                  <Field fullWidth type='email' id='email' name='email' label="Email" component={TextField} required InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={6}>
                  <Field fullWidth type='number' id='mobile' name='mobile' label="Mobile" component={TextField} InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIphoneIcon />
                      </InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={6}>
                  <Field fullWidth type='number' id='telephone' name='telephone' label="Tel (Home)" component={TextField} InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={12}>
                  <Field fullWidth type='text' id='address' name='address' label="Address" component={TextField} required InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    )}}/>
                </Grid>

                {/* <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="subject">
                      Prefer Subjects
                    </InputLabel>
                    <Field
                      component={Select}
                      type="text"
                      name="subject"
                      multiple={true}
                      inputProps={{name: 'subject', id: 'subject'}}
                    >
                      {subjects.map(subject => (
                        <MenuItem value={subject.name} key={subject.id}>{subject.name}</MenuItem>  
                      ))}
                    </Field>
                  </FormControl>
                </Grid> */}

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="region">Region</InputLabel>
                    <Field
                      component={Select}
                      name="region"
                      startAdornment={
                        <InputAdornment position="start">
                          <PublicIcon />
                        </InputAdornment>}
                      inputProps={{ name: 'region', id: "region" }}
                      >
                      {regions.map(region => 
                        <MenuItem key={region.id} value={region.name}>
                          {region.name}
                        </MenuItem>)}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="campus">Campus</InputLabel>
                    <Field
                      component={Select}
                      name="campus"
                      startAdornment={
                        <InputAdornment position="start">
                          <LocationCityIcon />
                        </InputAdornment>}
                      inputProps={{ name: 'campus', id: "campus" }}>
                    {campuses.map(c => 
                      <MenuItem key={c.id} value={c.name}>
                        {c.name}
                      </MenuItem>)}
                    </Field>
                  </FormControl>
                </Grid>

                

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="grade">Grade</InputLabel>
                    <Field
                      component={Select}
                      name="grade"
                      startAdornment={
                        <InputAdornment position="start">
                          <SchoolIcon />
                        </InputAdornment>}
                      inputProps={{ name: 'grade', id: "grade" }}>
                    {grades.map(grade => 
                      <MenuItem key={grade.id} value={grade.name}>
                        {grade.name}
                      </MenuItem>)}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <Field fullWidth type='number' id='workWithChildrenNum' name='workWithChildrenNum' label="Check Number" component={TextField} InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CheckCircleIcon />
                      </InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={6}>
                  <Field fullWidth component={DatePicker} name='workWithChildrenExp' label="Expiry Date" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>

                <Grid item xs={12}>
                  {isSubmitting && <LinearProgress />}
                </Grid>
                
                <Grid item xs={12} >
                  <Typography className={classes.subTitle} variant="h5" component="h6" align="center" color="textSecondary">Availability</Typography>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Field component={CheckboxWithLabel} type="checkbox" name="monday" Label={{ label: 'Monday' }} />
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='startMonday' label="From" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}} />
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='endMonday' label="To" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Field component={CheckboxWithLabel} type="checkbox" name="tuesday" Label={{ label: 'Tuesday' }}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='startTuesday' label="From" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='endTuesday' label="To" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Field component={CheckboxWithLabel} type="checkbox" name="wednesday" Label={{ label: 'Wednesday' }}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='startWednesday' label="From" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='endWednesday' label="To" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Field component={CheckboxWithLabel} type="checkbox" name="thursday" Label={{ label: 'Thursday' }}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='startThursday' label="From" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='endThursday' label="To" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Field component={CheckboxWithLabel} type="checkbox" name="friday" Label={{ label: 'Friday' }}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='startFriday' label="From" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='endFriday' label="To" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Field component={CheckboxWithLabel} type="checkbox" name="saturday" Label={{ label: 'Saturday' }}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='startSaturday' label="From" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}} />
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='endSaturday' label="To" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}} />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Field component={CheckboxWithLabel} type="checkbox" name="sunday" Label={{ label: 'Sunday' }}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='startSunday' label="From" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}}/>
                </Grid>
                <Grid item xs={6} sm={4}  >
                  <Field fullWidth component={TimePicker} name='endSunday' label="To" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"><ScheduleIcon /></InputAdornment>
                    )}} />
                </Grid>
                
               
                
                <Grid item style={{marginTop: 20}}>
                  <Button className={classes.button} variant="contained" color="secondary" type='submit' disabled={!isValid || isSubmitting}>Submit</Button>
                  <Button variant="contained" type='reset'>Reset</Button>
                </Grid>
              </Grid>
              </Paper>


          {/* <div className='form-control'>
            <label htmlFor='channel'>Channel</label>
            <Field type='text' id='channel' name='channel' />
            <ErrorMessage name="channel" />
          </div>

          <div className='form-control'>
            <label htmlFor='comments'>Comments</label>
            <Field as='textarea' id='comments' name='comments' />
            <ErrorMessage name="comments" component={TextError} />
          </div>

          <div className='form-control'>
            <label htmlFor='address'>Address</label>
            <FastField name='address'>
              {props => {
                const { field, form, meta } = props
                return (
                  <div>
                    <input type="text" id="address" {...field} />
                    {meta.touched && meta.error && <div>{meta.error}</div>}
                  </div>
                )
              }}
            </FastField>
          </div>

          <div className='form-control'>
            <label htmlFor='facebook'>Facebook Profile</label>
            <Field type="text" id='facebook' name='social.facebook' />
          </div>
          <div className='form-control'>
            <label htmlFor='twitter'>Twitter Profile</label>
            <Field type="text" id='twitter' name='social.twitter' />
          </div>

          <div className='form-control'>
            <label htmlFor='primaryPh'>Primary phone number</label>
            <Field type="text" id='primaryPh' name='phoneNumbers[0]' />
          </div>
          <div className='form-control'>
            <label htmlFor='secondaryPh'>Secondary phone number</label>
            <Field type="text" id='secondaryPh' name='phoneNumbers[1]' />
          </div>

          <div className="form-control">
            <label>List of phone numbers</label>
            <FieldArray name='phNumbers'>
              {
                (fieldArrayProps) => {
                  const {push, remove, form: {values: {phNumbers}}} = fieldArrayProps
                  return <div>
                    {phNumbers.map((phNumber, index) => (
                      <div key={index}>
                        <Field name={`phNumbers[${index}]`} />
                        {index > 0 && <button type='button' onClick={() => remove(index)}> - </button>}
                        <button type='button' onClick={() => push('')}> + </button>
                      </div>
                    ))}
                  </div>
                }
              }
            </FieldArray>
          </div> */}
            
        </Form>)}}
      </Formik>
        
      </MuiPickersUtilsProvider>

      <Typography variant="body2" color="colorSecondary" align="center" style={{marginTop: 30, marginBottom: 40}}>
        {'Copyright © '}
        <Link color="inherit" href="/">North Shore Coaching Collage</Link>{' '}
        {new Date().getFullYear()}
      </Typography>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Your availability form has been successfully submited
        </Alert>
      </Snackbar>
    </Container>
  </>
  )
}

export default TeacherAvailability

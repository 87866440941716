import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from "@material-ui/core";
import DrawerContext from "../components/DrawerContext";
import TermReportWriting from "../components/TermReportWriting";
import TermReportReview from "../components/TermReportReview";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  tab: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  content: {
    flexGrow: 1,
    minHeight: "80vh",
    overflow: "auto",
    backgroundColor: "#fafafa",
    marginLeft: "-50px",
  },
  contentShift: {
    flexGrow: 1,
    minHeight: "80vh",
    overflow: "auto",
    backgroundColor: "#fafafa",
  },
}));

const TermReport = () => {
  const classes = useStyles();

  const drawerOpen = useContext(DrawerContext);

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  const renderTermReport = () => {
    const role = sessionStorage.getItem("role");
    if (
      role === "State Teacher" ||
      role === "Teacher" ||
      role === "Limited State Teacher" ||
      role === "Admin"
    ) {
      if (currentTab === 0) {
        return <TermReportWriting />;
      } else {
        return <TermReportReview />;
      }
    } else if (role === "Campus Director" || role === "State Director") {
      return <TermReportReview />;
    }
  };

  const showTab = () => {
    const role = sessionStorage.getItem("role");
    if (
      role === "State Teacher" ||
      role === "Teacher" ||
      role === "Limited State Teacher" ||
      role === "Admin"
    ) {
      return (
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          className={classes.tab}
        >
          <Tab label="Create Report" icon={<AddCircleIcon />} />
          <Tab label="Review Reports" icon={<EditIcon />} />
        </Tabs>
      );
    }
  };
  return (
    <main className={clsx(classes.content, drawerOpen && classes.contentShift)}>
      <div className={classes.appBarSpacer} />
      {showTab()}
      {renderTermReport()}
    </main>
  );
};

export default TermReport;
import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, Grid, Dialog, DialogTitle, DialogContent, Button, MenuItem, InputAdornment } from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui'
import MuiTextField from '@material-ui/core/TextField';
import { Formik, Form, Field } from 'formik'
import RoleType from "../utils/RoleTypes";
import * as Yup from 'yup'
import LockIcon from '@material-ui/icons/Lock';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PublicIcon from "@material-ui/icons/Public";
import { Autocomplete } from "formik-material-ui-lab";
import { useSnackbar } from "notistack";
import { API } from "../api";

const UserManagementAddNewUser = ({
  handleClose,
  open,
  title,
  addNewUser,
  campuses,
  regions,
  roles,
  authenticatedRole,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [newRoles, setNewRoles] = useState([]);

  const validationSchema = Yup.object({
    RoleID: Yup.number().required("Required"),
    firstname: Yup.string().max(45).required("Required"),
    lastname: Yup.string().max(45).required("Required"),
    shortname: Yup.string().when("RoleID", (role) => {
      if (role === 2 || role === 6 || role === 7) {
        return Yup.string().required("Required");
      } else {
        return Yup.string();
      }
    }),
    username: Yup.string().max(20).required("Required"),
    password: Yup.string().min(6).max(40).required("Required"),
  });

  const onSubmit = ({
    lastname,
    firstname,
    shortname,
    username,
    password,
    RoleID,
    region,
    campus,
    multiCampus,
  }) => {
    const newUser = {
      Surname: lastname,
      GivenName: firstname,
      ShortName: shortname,
      Username: username,
      Password: password,
      RoleID,
      CentreIDs: campus
        ? [].concat(campus)
        : multiCampus.map((c) => c.CentreID),
      RegionID: region,
    };

    API({
      method: "post",
      url: "/staff",
      data: newUser,
    })
      .then((response) => {
        addNewUser(response.data);
        enqueueSnackbar("User has been successfully added", {
          variant: "success",
        });
        handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(`Invalid centres or Duplicate username`, {
          variant: "warning",
        });
      });
  };

  useEffect(() => {
    const role = sessionStorage.getItem("role");
    switch (role) {
      case "State Director":
        setNewRoles(roles.filter((r) => r.RoleID !== 5));
        break;

      case "Campus Director":
        setNewRoles(roles.filter((r) => r.RoleID === 2));
        break;
      default:
        setNewRoles(roles);
    }
  }, []);

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              RoleID:
                sessionStorage.getItem("role") === "Campus Director" ? 2 : "",
              firstname: "",
              lastname: "",
              shortname: "",
              username: "",
              password: "",
              region:
                sessionStorage.getItem("role") === "Admin"
                  ? ""
                  : Number(JSON.parse(sessionStorage.getItem("user")).RegionID),
              campus:
                sessionStorage.getItem("role") === "Campus Director"
                  ? JSON.parse(sessionStorage.getItem("user")).CentreIDs[0]
                  : "",
              multiCampus: [],
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValid, values, touched, errors }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="RoleID">Role</InputLabel>
                        <Field
                          component={Select}
                          name="RoleID"
                          inputProps={{ name: "RoleID", id: "RoleID" }}
                        >
                          {newRoles.map(({ RoleID, Name }) => (
                            <MenuItem key={RoleID} value={RoleID}>
                              {Name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        fullWidth
                        type="text"
                        id="firstname"
                        name="firstname"
                        label="First Name"
                        component={TextField}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        fullWidth
                        type="text"
                        id="lastname"
                        name="lastname"
                        label="Last Name"
                        component={TextField}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        type="text"
                        id="shortname"
                        name="shortname"
                        label="Short Name"
                        component={TextField}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        type="text"
                        id="username"
                        name="username"
                        label="Username"
                        component={TextField}
                        autoComplete="off"
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        type="password"
                        id="password"
                        name="password"
                        label="Password"
                        component={TextField}
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="region">Region</InputLabel>
                        <Field
                          component={Select}
                          name="region"
                          disabled={
                            authenticatedRole === "Admin" ? false : true
                          }
                          startAdornment={
                            <InputAdornment position="start">
                              <HomeWorkIcon />
                            </InputAdornment>
                          }
                          inputProps={{ name: "region", id: "region" }}
                        >
                          {regions?.map((r) => (
                            <MenuItem key={r.RegionID} value={r.RegionID}>
                              {r.Name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>

                    {(values.RoleID === RoleType.CAMPUSDIRECTOR ||
                      values.RoleID === RoleType.TEACHER ||
                      values.RoleID === RoleType.CUSTOMERSERVICESUPPORT) && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="campus">Campus</InputLabel>
                          <Field
                            component={Select}
                            name="campus"
                            startAdornment={
                              <InputAdornment position="start">
                                <PublicIcon />
                              </InputAdornment>
                            }
                            disabled={
                              authenticatedRole === "Campus Director"
                                ? true
                                : false
                            }
                            inputProps={{ name: "campus", id: "campus" }}
                          >
                            {campuses?.map(({ CentreID, Name }) => (
                              <MenuItem key={CentreID} value={CentreID}>
                                {Name}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>
                    )}

                    {values.RoleID === RoleType.LIMITEDSTATETEACHER && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Field
                            multiple
                            name="multiCampus"
                            component={Autocomplete}
                            options={campuses}
                            getOptionLabel={(option) => option.Name}
                            renderInput={(AutocompleteRenderInputParams) => (
                              <MuiTextField
                                {...AutocompleteRenderInputParams}
                                error={
                                  touched["multiCampus"] &&
                                  !!errors["multiCampus"]
                                }
                                helperText={errors["multiCampus"]}
                                label="Campuses"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item style={{ marginTop: 20, marginBottom: 10 }}>
                      <Button
                        style={{ marginRight: 20 }}
                        color="secondary"
                        type="submit"
                        disabled={!isValid || isSubmitting}
                      >
                        Submit
                      </Button>
                      <Button
                        style={{ marginRight: 20 }}
                        color="secondary"
                        type="reset"
                      >
                        Reset
                      </Button>
                      <Button color="primary" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};;

export default UserManagementAddNewUser

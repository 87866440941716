import axios from "axios";

const config = {
  // baseURL: "http://localhost:5000",
  // baseURL: "https://nonprodportal-api.north-shore.com.au",
  baseURL: "https://portal-api.north-shore.com.au",
};

const API = axios.create(config);

const getAuthToken = () => sessionStorage.getItem("token");

const authInterceptor = (config) => {
  config.headers["Authorization"] = getAuthToken();
  config.headers["Access-Control-Allow-Origin"] = "*";
  return config;
};

/** Adding the request interceptors */
API.interceptors.request.use(authInterceptor);

/** Adding the response interceptors */
// const errorInterceptor = (error) => {
//   // all the error responses
//   switch (error.response.status) {
//     case 400:
//       console.error(error.response.status, error.message);
//       //Vue.notify({type: 'warn', text: 'Nothing to display', title: 'Data Not Found'});
//       break;

//     case 401: // authentication error, logout the user
//       sessionStorage.removeItem("token");
//       sessionStorage.removeItem("user");
//       sessionStorage.removeItem("role");
//       sessionStorage.setItem("auth", false);
//       console.log(error.message);
//       document.location.href = "/";
//       //history.push("/sign-in?errorMessage=Session Expired. Please login again");
//       break;

//     case 403:
//       sessionStorage.removeItem("token");
//       sessionStorage.removeItem("user");
//       sessionStorage.removeItem("role");
//       sessionStorage.setItem("auth", false);
//       console.log(error.message);
//       document.location.href = "/";
//       //history.push("/sign-in?errorMessage=Invalid Username or Password");
//       break;

//     default:
//       console.error(error.response.status, error.message);
//     //Vue.notify({type: 'error', text: 'Server Error'});
//   }
//   return Promise.reject(error);
// };

// Interceptor for responses
const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      // yay!
      break;
    // any other cases
    default:
    // default case
  }

  return response;
};

API.interceptors.response.use(responseInterceptor);

export { API };

import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import Header from '../components/Header';
import { mainListItems } from '../components/menuItems';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer, IconButton, List } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DrawerContext from '../components/DrawerContext';
import AuthenticatedContext from '../components/AuthenticatedContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    height: '100vh',
    float: 'left',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  root: {
    flexGrow: 1,
  },
  logo: {
    marginRight: theme.spacing(3),
    height: "60px",
    maxWidth: "100px",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  offset: theme.mixins.toolbar
}));

const Layout = ({ children }) => {
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  const newChildren = React.Children.map(children, child => 
    React.cloneElement(child, { drawerOpenProps: {drawerOpen} })
  );

  const authContext = useContext(AuthenticatedContext);

  const renderDrawer = () => {
    if (
      sessionStorage.getItem("auth") === "true" ||
      authContext.userAuthenticated
    ) {
      return (
        <>
          <Header
            drawerOpenProps={drawerOpen}
            handleDrawerOpenProps={handleDrawerOpen}
          />
          <Drawer
            variant="permanent"
            classes={{
              root: classes.drawer,
              paper: clsx(
                classes.drawerPaper,
                !drawerOpen && classes.drawerPaperClose
              ),
            }}
            open={drawerOpen}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>{mainListItems(authContext.authenticatedRole)}</List>
          </Drawer>
        </>
      );
    } else {
      return (
        <Header
          drawerOpenProps={false}
          handleDrawerOpenProps={handleDrawerOpen}
        />
      );
    }
  }

  return (
    <DrawerContext.Provider value={drawerOpen}>
      <div>
        {authContext.userAuthenticated && renderDrawer()}
        <div className={classes.offset}></div>
        {newChildren}
      </div>
    </DrawerContext.Provider>
  );
}

export default Layout;
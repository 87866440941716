import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Container } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import DrawerContext from '../components/DrawerContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#fafafa',
    marginLeft: '-50px',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    height: '80vh',
    align: 'center',
  },
  contentShift: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#fafafa',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const Homework = props => {
  const classes = useStyles();

  const [selection, setSelection] = useState([]);

  const handleGridSelect = (newSelection) => {
    setSelection(newSelection.data);
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'yearLevel', headerName: 'Year Level', width: 150, type: 'number' },
    { field: 'day', headerName: 'Day', width: 100 },
    { field: 'time', headerName: 'Time', width: 130 },
    { field: 'subject', headerName: 'Subject', width: 130 },
    { field: 'givenName', headerName: 'Given Name', width: 160 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
  ];

  const rows = [
    { id: 1, yearLevel: 3, day: 'Saturday', time: 'Morning', subject: 'Mathematics', givenName: 'Chatswood Yr 3', lastName: 'X'},
    { id: 2, yearLevel: 5, day: 'Sunday', time: 'Afternoon', subject: 'English', givenName: 'Chatswood Yr 5', lastName: 'X'},
  ];

  const drawerOpen = useContext(DrawerContext);

  return (
    <main className={clsx(classes.content, drawerOpen && classes.contentShift)}>
      <Container className={classes.container}>
        <DataGrid rows={rows} columns={columns} pageSize={5} onRowSelected={(newSelection) => {
          handleGridSelect(newSelection);
        }} />
      </Container>
    </main>
  )
}

export default Homework;
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockIcon from "@material-ui/icons/Lock";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AuthenticatedContext from "../components/AuthenticatedContext";
import { useSnackbar } from "notistack";
import { API } from "../api";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "80%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  }
}));

const Signin = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  const authContext = useContext(AuthenticatedContext);

  const validationSchema = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const initialValues = {
    username: "",
    password: "",
  }

  const onSubmit = ({username, password}, {resetForm}) => {
    API.post("/login", { username, password })
      .then((response) => {
        if (response?.data?.success) {
          authContext.loginSuccess(
            roleName(response.data.user.RoleID),
            response.data.token,
            response.data.user
          );
          sessionStorage.setItem("role", roleName(response.data.user.RoleID));
          sessionStorage.setItem("auth", "true");
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem("user", JSON.stringify(response.data.user));
          history.push("/dashboard");
        }
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
        authContext.logoutSuccess();
        sessionStorage.setItem("auth", "false");
        sessionStorage.setItem("role", "");
        sessionStorage.setItem("token", null);
      });

    resetForm(initialValues);
  }

  const roleName = (roleID) => {
    switch (roleID) {
      case 1:
        return "Admin";
      case 2:
        return "Teacher";
      case 3:
        return "Student";
      case 4:
        return "Campus Director";
      case 5:
        return "State Director";
      case 6:
        return "State Teacher";
      case 7:
        return "Limited State Teacher";
      default:
        return null;
    }
  }

  return (
    <div className={classes.root}>
      <Card className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, values }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      type="text"
                      id="username"
                      label="Username"
                      name="username"
                      component={TextField}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      type="password"
                      id="password"
                      label="Password"
                      name="password"
                      component={TextField}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                    >
                      Sign In
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Card>
      <Typography variant="body2" color="textSecondary">
        North Shore Coaching College owns all the copyright in relation to the
        content available within the Online Assessment Portal, unless specified
        otherwise. Content must not be reproduced, published, shared or
        communicated without the permission of North Shore Coaching College. By
        logging in to the North Shore Online Assessment Portal Area, you agree
        to these Terms & Conditions.
      </Typography>
    </div>
  );
};

export default Signin;

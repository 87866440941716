import { IconButton, DialogTitle, DialogContent, FormControl, Dialog, Button, Grid, InputAdornment } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import React, { useState } from 'react'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LockIcon from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import { useSnackbar } from "notistack";
import { API } from "../api";

const UserManagementResetPassword = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = ({ password }) => {
    API.put(`/staff/password/${params.id}`, { Password: password })
      .then((response) => {
        if (response && response.status === 200) {
          enqueueSnackbar("User password has been successfully changed", {
            variant: "success",
          });
        }
        setOpen(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <IconButton size="small" color="primary" onClick={handleClickOpen}>
        <LockOpen />
      </IconButton>
      <Dialog onClose={handleClose} open={open} maxWidth="sm">
        <DialogTitle>Set New Password</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ password: "" }}
            validationSchema={Yup.object({
              password: Yup.string().min(6).max(40).required("Required"),
            })}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValid }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Field
                          fullWidth
                          type="password"
                          id="password"
                          name="password"
                          label="Password"
                          component={TextField}
                          required
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item style={{ marginTop: 20, marginBottom: 10 }}>
                      <Button
                        style={{ marginRight: 20 }}
                        color="secondary"
                        type="submit"
                        disabled={!isValid || isSubmitting}
                      >
                        Submit
                      </Button>
                      <Button color="primary" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserManagementResetPassword

import React, { useState } from "react";
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import { API } from "../api";
import axios from "axios";

const TermReportDelete = ({ params, deleteReportUI }) => {
  let source = axios.CancelToken.source();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);
  const handleReportDelete = () => {
    API({
      method: "delete",
      url: `/termreport/delete/${params.row.Term_reportID}`,
      cancelToken: source.token,
    })
      .then((response) => {
        source.cancel();
        if (response && response.status === 200) {
          enqueueSnackbar("Report has been successfully deleted", {
            variant: "success",
          });
          deleteReportUI(params.id);
          setOpenDialog(false);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <IconButton
        color="primary"
        disabled={
          params?.row?.Term_Report_State?.StateID === 5 ||
          params?.row?.Term_Report_State?.StateID === 6 ||
          (sessionStorage.getItem("role") !== "Campus Director" &&
            sessionStorage.getItem("role") !== "State Director" &&
            params?.row?.Term_Report_State?.StateID === 3) ||
          ((sessionStorage.getItem("role") === "Campus Director" ||
            sessionStorage.getItem("role") === "State Director") &&
            params?.row?.Term_Report_State?.StateID === 1)
        }
        onClick={handleClickOpen}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        onClose={handleDialogClose}
        open={openDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Delete Report</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReportDelete} color="primary">
            Delete
          </Button>
          <Button onClick={handleDialogClose} color="secondary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TermReportDelete;

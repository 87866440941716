import React, { useState } from 'react'
import { IconButton, DialogTitle, DialogContent, DialogActions, DialogContentText, Dialog, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack'
import { API } from '../api';
import axios from 'axios';

const UserManagementDeleteUser = ({params, deleteUser}) => {

  let source = axios.CancelToken.source()
  const {enqueueSnackbar} = useSnackbar()
  const [openDialog, setOpenDialog] = useState(false)
  const handleClickOpen = () => setOpenDialog(true)
  const handleDialogClose = () => setOpenDialog(false)
  const handleUserDelete = () => {
    API({
      method: "delete",
      url: `/staff/${params.id}`,
      cancelToken: source.token,
    })
      .then((response) => {
        source.cancel();
        if (response && response.status === 200) {
          enqueueSnackbar("User has been successfully deleted", {
            variant: "success",
          });
          deleteUser(params.id);
          setOpenDialog(false);
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <IconButton size="small" color="primary" onClick={handleClickOpen}><DeleteIcon /></IconButton>
      <Dialog onClose={handleDialogClose} open={openDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUserDelete} color="primary">
            delete
          </Button>
          <Button onClick={handleDialogClose} color="secondary" autoFocus>
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UserManagementDeleteUser


import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Container,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
} from "@material-ui/core";
import { TextField, Select } from "formik-material-ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { API } from "../api";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import TermReportNSW from "../components/TermReportNSW";
import TermReportQLD from "./TermReportQLD";
import TermReportVIC from "./TermReportVIC";
import { useSnackbar } from "notistack";
import SearchIcon from "@material-ui/icons/Search";
import TermReportWA from "./TermReportWA";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    minWidth: 120,
  },
  form: {
    marginBottom: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
  },
  paper: {
    padding: "24px",
  },
}));

function getCentreName(params) {
  return params.row.Centre.Name;
}

function getRoomNumber(params) {
  return params.row.RoomNumber;
}

function getStudentSubjects(params) {
  let subjects = params.row.SubjectName;
  subjects = subjects.replace(/Year \d+|Level \d+/g, "");
  return subjects.replace(/\|/g, ", ");
}

const TermReportWriting = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selection, setSelection] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [grades, setGrades] = useState([]);
  const [term, setTerm] = useState("");
  const [grade, setGrade] = useState("");
  const [students, setStudents] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [containSearchRoomNumber, setContainSearchRoomNumber] = useState(false);

  const handlePageSizeChange = ({ pageSize }) => setPageSize(pageSize);

  const validationSchema =
    JSON.parse(sessionStorage.getItem("user")).RegionID === 2
      ? Yup.object().shape(
          {
            classGrade: Yup.object(),
            givenName: Yup.string()
              .max(45)
              .when("lastName", {
                is: (lastName) => !lastName,
                then: Yup.string().required(),
              }),
            lastName: Yup.string()
              .max(45)
              .when("givenName", {
                is: (givenName) => !givenName,
                then: Yup.string().required(),
              }),
            subject: Yup.string().max(200),
          },
          ["givenName", "lastName"]
        )
      : JSON.parse(sessionStorage.getItem("user")).RegionID === 4
      ? Yup.object({
          classGrade: Yup.object(),
          givenName: Yup.string().max(45),
          lastName: Yup.string().max(45),
          subject: Yup.string().max(200),
          roomNumber: Yup.string().max(200),
        })
      : Yup.object({
          classGrade: Yup.object(),
          givenName: Yup.string().max(45),
          lastName: Yup.string().max(45),
          subject: Yup.string().max(200),
        });

  const handleGridSelect = (newSelection) => {
    setSelection(newSelection.data);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getCurrentTerm = async () => {
    const response = await API.get("/currentterm");
    return response.data;
  };

  const populateGrades = async () => {
    const response = await API.get("/years");
    return response.data;
  };

  function getGradeName(params) {
    const currGrade = grades.filter(
      (g) => g.Region_yearID === params.row.CourseGrade
    );
    if (JSON.parse(sessionStorage.getItem("user")).RegionID === 2) {
      return currGrade[0].FullName.replace(/Year/, "Level");
    }
    return currGrade[0]?.FullName;
  }

  function renderReportDialog() {
    switch (JSON.parse(sessionStorage.getItem("user")).RegionID) {
      case 2:
        return (
          <TermReportQLD
            year={
              grades.filter((g) => g.Region_yearID === selection.CourseGrade)[0]
            }
            term={term}
            selection={selection}
            handleClose={handleClose}
            mode={"create"}
          />
        );
      case 3:
        return (
          <TermReportVIC
            year={
              grades.filter((g) => g.Region_yearID === selection.CourseGrade)[0]
            }
            term={term}
            selection={selection}
            handleClose={handleClose}
            mode={"create"}
          />
        );
      case 4:
        return (
          <TermReportWA
            year={
              grades.filter((g) => g.Region_yearID === selection.CourseGrade)[0]
            }
            term={term}
            selection={selection}
            handleClose={handleClose}
            mode={"create"}
          />
        );
      default:
        return (
          <TermReportNSW
            year={
              grades.filter((g) => g.Region_yearID === selection.CourseGrade)[0]
            }
            term={term}
            selection={selection}
            handleClose={handleClose}
            mode={"create"}
          />
        );
    }
  }

  const onSubmit = (
    { classGrade, givenName, lastName, roomNumber, subject },
    { resetForm }
  ) => {
    setGrade(classGrade);

    const params = {
      TermName: term.Name,
      GivenName: givenName,
      LastName: lastName,
      RoomNumber: roomNumber,
      Subject: subject,
      Region_yearID: classGrade.Region_yearID,
    };

    if (roomNumber !== "") {
      setContainSearchRoomNumber(true);
    } else setContainSearchRoomNumber(false);

    setStudents([]);

    handleSearch(params).then((data) => {
      if (data?.length > 0) {
        setStudents(data);
        resetForm({
          values: {
            classGrade: classGrade,
            givenName: givenName,
            lastName: lastName,
            roomNumber: roomNumber,
            subject: subject,
          },
        });
      } else {
        enqueueSnackbar("There's no student match the search criteria.", {
          variant: "warning",
        });
        resetForm({
          values: {
            classGrade: "",
            givenName: "",
            lastName: "",
            roomNumber: "",
            subject: "",
          },
        });
        setStudents([]);
      }
    });
  };

  const handleSearch = async (params) => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user.RegionID === 8) {
      let term = params.TermName;
      term = term.replace(/ \(HK\)/, "");
      params.TermName = term;
    }
    try {
      const response = await API.get("/students", { params });
      let studentData = response.data;
      studentData.forEach((item, i) => {
        item.id = i + 1;
      });
      return studentData;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: "StudentID",
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "GivenName",
      headerName: "Given Name",
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: "Surname",
      headerName: "Last Name",
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: "ClassGrade",
      hide: grade,
      headerName: "Class Grade",
      width: 150,
      valueGetter: getGradeName,
      disableColumnMenu: true,
    },
    {
      field: "Centre",
      headerName: "Campus",
      width: 150,
      valueGetter: getCentreName,
      disableColumnMenu: true,
    },
    {
      field: "RoomNumber",
      headerName: "Room Number",
      width: 150,
      valueGetter: getRoomNumber,
      disableColumnMenu: true,
      hide:
        JSON.parse(sessionStorage.getItem("user")).RegionID !== 4 ||
        !containSearchRoomNumber,
    },
    {
      field: "SubjectName",
      headerName: "Subjects",
      width: 600,
      valueGetter: getStudentSubjects,
      disableColumnMenu: true,
    },
  ];

  useEffect(() => {
    getCurrentTerm().then((termData) => {
      setTerm(termData);
    });
    populateGrades().then((gradeData) => {
      setGrades(
        gradeData.map((g) => {
          return {
            Region_yearID: g.Region_yearID,
            FullName: g.FullName,
          };
        })
      );
    });
  }, []);

  return (
    <Container className={classes.container} maxWidth={false}>
      <Paper elevation={1} className={classes.paper}>
        <Formik
          initialValues={{
            classGrade: "",
            givenName: "",
            lastName: "",
            roomNumber: "",
            subject: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, values }) => {
            return (
              <Form className={classes.form}>
                {JSON.parse(sessionStorage.getItem("user")).RegionID !== 2 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="classGrade">Grade</InputLabel>
                    <Field component={Select} name="classGrade">
                      {grades.map((g) => (
                        <MenuItem key={g.Region_yearID} value={g}>
                          {g.FullName}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                )}

                <FormControl className={classes.formControl}>
                  <Field
                    type="text"
                    id="givenName"
                    name="givenName"
                    label="Given Name"
                    component={TextField}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    component={TextField}
                  />
                </FormControl>
                {JSON.parse(sessionStorage.getItem("user")).RegionID === 4 && (
                  <FormControl className={classes.formControl}>
                    <Field
                      type="text"
                      id="roomNumber"
                      name="roomNumber"
                      label="Room Number"
                      component={TextField}
                    />
                  </FormControl>
                )}
                <FormControl className={classes.formControl}>
                  <Field
                    type="text"
                    id="subject"
                    name="subject"
                    label="Subject"
                    component={TextField}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Button
                    startIcon={<SearchIcon />}
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={!isValid || isSubmitting}
                  >
                    Search
                  </Button>
                </FormControl>
                <Dialog
                  disableBackdropClick
                  open={openDialog}
                  onClose={handleClose}
                  maxWidth="md"
                  fullWidth
                >
                  {selection && renderReportDialog()}
                </Dialog>
              </Form>
            );
          }}
        </Formik>
        <DataGrid
          loading={loading}
          autoHeight
          rows={students}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onRowSelected={(newSelection) => {
            handleGridSelect(newSelection);
          }}
          disabled={loading}
        />
      </Paper>
    </Container>
  );
};

export default TermReportWriting;

import React, { useEffect, useState } from 'react';
import{ BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { Container, Fade } from "@material-ui/core";

import Signin from './pages/Signin';
import Dashboard from './pages/Dashboard';
import TeacherAvailability from './components/TeacherAvailability';
import TeacherAvailabilityAll from './components/TeacherAvailabilityAll';
import Layout from './layouts/Layout';
import TermReport from './pages/TermReport';
import UpdateDetails from './pages/UpdateDetails';
import Homework from './pages/Homework';
import AuthenticatedContext from './components/AuthenticatedContext';
import UserManagement from './pages/UserManagement';
import { SnackbarProvider } from 'notistack'

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('auth') === 'true');

  const loginSuccess = (role, token, user) => {
    setIsAuthenticated(true);
    setUserRole(role);
    setUserToken(token);
    setUser(user);
  };

  const logoutSuccess = () => {
    setIsAuthenticated(false);
    setUserRole('');
    setUserToken(null);
    setUser(null);
  }

  const [userRole, setUserRole] = useState(sessionStorage.getItem('role') || '');
  const [userToken, setUserToken] = useState(sessionStorage.getItem('token'));
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));

  const globalSettings = {
    userAuthenticated: isAuthenticated,
    authenticatedRole: userRole,
    authenticatedToken: userToken,
    authenticatedUser: user,
    loginSuccess,
    logoutSuccess,
  };

  useEffect(() => {
    if (isAuthenticated) {
      document.title = `NSCC ${userRole} Portal`;
    } else {
      document.title = `NSCC Portal`;
    }
  }, [userRole, isAuthenticated]);

  return (
    <AuthenticatedContext.Provider value={globalSettings}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        TransitionComponent={Fade}
      >
        <Router>
          <Layout>
            <Switch>
              <Route path="/dashboard">
                {isAuthenticated ? <Dashboard /> : <Redirect to="/" />}
              </Route>
              <Route path="/termreport">
                {isAuthenticated ? <TermReport /> : <Redirect to="/" />}
              </Route>
              <Route path="/updatedetails">
                {isAuthenticated ? <UpdateDetails /> : <Redirect to="/" />}
              </Route>
              <Route path="/usermanagement">
                {isAuthenticated ? <UserManagement /> : <Redirect to="/" />}
              </Route>
              <Route path="/homework">
                {isAuthenticated ? <Homework /> : <Redirect to="/" />}
              </Route>
              <Route path="/teacheravailability">
                {isAuthenticated ? (
                  <TeacherAvailability />
                ) : (
                  <Redirect to="/" />
                )}
              </Route>
              <Route path="/teacher-all">
                {isAuthenticated ? (
                  <TeacherAvailabilityAll />
                ) : (
                  <Redirect to="/" />
                )}
              </Route>
              <Route path="/">
                <Container style={{ height: "80vh", padding: "0px" }}>
                  {isAuthenticated ? <Redirect to="/dashboard" /> : <Signin />}
                </Container>
              </Route>
            </Switch>
          </Layout>
        </Router>
      </SnackbarProvider>
    </AuthenticatedContext.Provider>
  );
}

export default App;

import React, { useState, useEffect, useContext } from 'react'
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Paper, Button } from "@material-ui/core";
import RoleType from "../utils/RoleTypes";
import clsx from "clsx";
import AuthenticatedContext from "../components/AuthenticatedContext";
import UserManagementAddNewUser from "../components/UserManagementAddNewUser";
import UserManagementResetPassword from "../components/UserManagementResetPassword";
import UserManagementDeleteUser from "../components/UserManagementDeleteUser";
import UserManagementEditUser from "../components/UserManagementEditUser";
import { API } from "../api";

const useStyles = makeStyles((theme) => ({
  paper: { padding: "24px", paddingTop: "8px" },
  top: {
    display: "flex",
    margin: theme.spacing(2, 0),
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: { marginBottom: 0 },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  content: {
    paddingTop: theme.spacing(3),
    flexGrow: 1,
    overflow: "auto",
    backgroundColor: "#fafafa",
    marginLeft: "-50px",
  },
}));

const UserManagement = () => {
  const { authenticatedRole } = useContext(AuthenticatedContext);
  const [rows, setRows] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [regions, setRigons] = useState([]);

  useEffect(() => {
    API.get("/staffs")
      .then((response) => {
        const rows = response.data.map((user) => ({
          id: user.StaffID,
          Surname: user.Surname || "",
          GivenName: user.GivenName || "",
          Username: user.Username || "",
          ShortName: user.ShortName || "",
          role: user.Role?.RoleName || "Customer Service Support",
          campus:
            user.Centres.length > 1
              ? user.Centres?.map((centre) => centre.Name).join(", ")
              : user.Centres[0]?.Name,
          region: user.Region.Name || "",
        }));
        setRows(rows);
      })
      .catch((error) => console.log(error));

    API.get("/centres")
      .then((response) => setCampuses(response.data))
      .catch((error) => console.log(error));

    API.get("/regions")
      .then((response) => setRigons(response.data))
      .catch((error) => console.log(error));
  }, []);

  const columns = [
    {
      field: "",
      sortable: false,
      filterable: false,
      disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
      headerName: "Actions",
      width: 130,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <UserManagementEditUser
            params={params}
            editUser={editUser}
            regions={regions}
            campuses={campuses}
            roles={roles}
            authenticatedRole={authenticatedRole}
          />
          <UserManagementResetPassword params={params} />
          <UserManagementDeleteUser params={params} deleteUser={deleteUser} />
        </>
      ),
    },
    { field: "id", headerName: "ID", width: 100 },
    { field: "Surname", headerName: "Surname", width: 170 },
    { field: "GivenName", headerName: "Given Name", width: 170 },
    { field: "Username", headerName: "Username", width: 170 },
    { field: "ShortName", headerName: "Short Name", width: 170 },
    { field: "role", headerName: "Role", width: 200 },
    { field: "campus", headerName: "Campus", width: 200 },
    { field: "region", headerName: "Region", width: 150 },
  ];

  const roles = [
    {
      Name: "State Director",
      RoleID: RoleType.STATEDIRECTOR,
      authorize: [RoleType.ADMIN],
    },
    {
      Name: "Campus Director",
      RoleID: RoleType.CAMPUSDIRECTOR,
      authorize: [RoleType.ADMIN, RoleType.STATEDIRECTOR],
    },
    {
      Name: "State Teacher",
      RoleID: RoleType.STATETEACHER,
      authorize: [RoleType.ADMIN, RoleType.STATEDIRECTOR],
    },
    {
      Name: "Limited State Teacher",
      RoleID: RoleType.LIMITEDSTATETEACHER,
      authorize: [RoleType.ADMIN, RoleType.STATEDIRECTOR],
    },
    {
      Name: "Teacher",
      RoleID: RoleType.TEACHER,
      authorize: [
        RoleType.ADMIN,
        RoleType.STATEDIRECTOR,
        RoleType.CAMPUSDIRECTOR,
      ],
    },
    {
      Name: "Customer Service Support",
      RoleID: RoleType.CUSTOMERSERVICESUPPORT,
      authorize: [
        RoleType.ADMIN,
        RoleType.STATEDIRECTOR,
        RoleType.CAMPUSDIRECTOR,
      ],
    },
  ];

  const classes = useStyles();
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState(false);

  const handlePageSizeChange = ({ pageSize }) => setPageSize(pageSize);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const addNewUser = (newUser) => {
    const newRow = {
      id: newUser.StaffID,
      Surname: newUser.Surname || "",
      GivenName: newUser.GivenName || "",
      Username: newUser.Username || "",
      ShortName: newUser.ShortName || "",
      role: newUser.Role.RoleName || "",
      campus:
        newUser.Centres.length > 1
          ? newUser.Centres?.map((centre) => centre.Name).join(", ")
          : newUser.Centres[0]?.Name,
      region: newUser.Region.Name || "",
    };
    setRows([newRow, ...rows]);
  };
  const deleteUser = (id) => {
    const newRows = rows?.filter((row) => row.id !== id);
    setRows(newRows);
  };

  const editUser = (id, editedUser) => {
    const newRows = rows?.map((row) => (row.id === id ? editedUser : row));
    setRows(newRows);
  };

  return (
    <main className={clsx(classes.content)}>
      <Container className={classes.container} maxWidth={false}>
        <Paper className={classes.paper} elevation={0}>
          <div className={classes.top}>
            <Typography
              color="primary"
              variant="h6"
              gutterBottom
              className={classes.title}
            >
              User Management
            </Typography>
            <Button
              onClick={handleClickOpen}
              className={classes.button}
              color="primary"
              variant="contained"
            >
              New User
            </Button>
            <UserManagementAddNewUser
              open={open}
              title="New User"
              handleClose={handleClose}
              addNewUser={addNewUser}
              regions={regions}
              campuses={campuses}
              roles={roles}
              authenticatedRole={authenticatedRole}
            />
          </div>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            components={{ Toolbar: GridToolbar }}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[25, 50, 100]}
            disableSelectionOnClick
          />
        </Paper>
      </Container>
    </main>
  );
};

export default UserManagement

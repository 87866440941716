import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Button,
  Typography,
  DialogContent,
  DialogTitle,
  InputLabel,
  LinearProgress,
  Dialog,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MuiTextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import { TextField, Select } from "formik-material-ui";
import { Formik, Form, Field } from "formik";
import { API } from "../api";
import { useSnackbar } from "notistack";
import { Autocomplete } from "formik-material-ui-lab";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  select: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  body1: {
    padding: 4,
  },
  title: {
    textAlign: "center",
  },
}));

const TermReportVIC = ({
  selection,
  handleClose,
  mode,
  year,
  handleApproveUI,
  handleRejectUI,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [terms, setTerms] = useState([]);
  const [currTerm, setCurrTerm] = useState("");
  const [comments, setComments] = useState([]);
  const formRef = useRef();
  const lastSubmission = JSON.parse(sessionStorage.getItem("lastSubmission"));
  const [reportData, setReportData] = useState("");
  const [isApprove, setIsApprove] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isTeacherSubmit, setIsTeacherSubmit] = useState(false);

  const validationSchema = Yup.object({
    Subject:
      // mode === "create" ? Yup.object().required("Required") : Yup.string(),
      Yup.object().required("Required"),
    Term: mode === "create" ? Yup.string().required("Required") : Yup.string(),
    a1: Yup.object({
      Performance: Yup.string().required("Required"),
    }),
    a2: Yup.object({
      Performance: Yup.string().required("Required"),
    }),
    a3: Yup.object({
      Performance: Yup.string().required("Required"),
    }),
    a4: Yup.object({
      Performance: Yup.string().required("Required"),
    }),
    a5: Yup.object({
      Performance: Yup.string().required("Required"),
    }),
    a6: Yup.object({
      Performance: Yup.string().required("Required"),
    }),
    p1: Yup.object({
      Performance: Yup.string().required("Required"),
    }),
    p2: Yup.object({
      Performance: Yup.string().required("Required"),
    }),
    p3: Yup.object({
      Performance: Yup.string().required("Required"),
    }),
    c1: Yup.object({
      Performance: Yup.string().max(5000).required("Required"),
    }),
    feedback:
      openFeedbackDialog === true
        ? Yup.string().max(500).required("Required")
        : Yup.string().max(500),
  });

  const handleFeedbackOpen = () => {
    setOpenFeedbackDialog(true);
  };

  const handleFeedbackClose = () => {
    setOpenFeedbackDialog(false);
  };

  const populateSubjects = async () => {
    const response = await API.get("/subjects");
    return response.data;
  };

  const handleApprove = () => {
    setIsApprove(true);
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleSubmitReport = async () => {
    setIsSubmit(true);
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleTeacherSubmit = async () => {
    setIsTeacherSubmit(true);
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const populateTerms = async () => {
    const response = await API.get("/terms");
    return response.data;
  };

  const getCurrentTerm = async () => {
    const response = await API.get("/currentterm");
    return response.data;
  };

  const populateComments = async () => {
    const response = await API.get("/comments");
    return response.data;
  };

  const renderMenuItems = () => {
    const menuItems = [
      "Well above expectations",
      "Above expectations",
      "Meeting expectations",
      "Below expectations",
    ];
    return menuItems.map((menuItem) => (
      <MenuItem key={menuItem} value={menuItem}>
        {menuItem}
      </MenuItem>
    ));
  };

  const renderCommentOne = () => {
    let section1 = comments.filter((c) => c.Section === 1);
    section1 = section1.map((s) => ({
      ...s,
      Comment: s.Comment.replace(
        /\[Name\]/g,
        selection.GivenName.toLowerCase()
          .trim()
          .split(/\s+/)
          .map(function (word) {
            return word.replace(word[0], word[0].toUpperCase());
          })
          .join(" ")
      ),
    }));
    return section1.map((s) => (
      <MenuItem key={s.CommentID} value={s.Comment}>
        {s.Comment}
      </MenuItem>
    ));
  };

  const renderCommentTwo = () => {
    let section2 = comments.filter((c) => c.Section === 2);
    section2 = section2.map((s) => ({
      ...s,
      Comment: s.Comment.replace(
        /\[Name\]/g,
        selection.GivenName.toLowerCase()
          .trim()
          .split(/\s+/)
          .map(function (word) {
            return word.replace(word[0], word[0].toUpperCase());
          })
          .join(" ")
      ),
    }));
    return section2.map((s) => (
      <MenuItem key={s.CommentID} value={s.Comment}>
        {s.Comment}
      </MenuItem>
    ));
  };

  const onSubmit = async (values) => {
    try {
      const Subject = values.Subject;
      values.a2.Objective = Subject.Objective?.Objective;

      if (Subject.Report_subjectID === 75) {
        values.a1.Objective = "Ability to complete testing tasks";
        values.a2.Objective = "Improvement in writing & comprehension ability";
        values.a3.Objective = "Improvement in mathematics ability";
        values.a4.Objective = "Improvement in verbal reasoning ability";
        values.a5.Objective = "Improvement in numerical reasoning ability";
        values.a6.Objective = "Overall improvement in testing performance";
      } else {
        values.a1.Objective = "Understanding of key concepts";
        values.a3.Objective = "Ability to complete class tasks";
        values.a4.Objective = "Ability to complete homework tasks";
        values.a5.Objective = "Performance in assessment tasks";
        values.a6.Objective =
          "Overall improvement in application and performance";
      }

      if (mode === "create") {
        values.CourseGrade = year.Region_yearID;
        if (values.comment2) {
          values.c1.Performance =
            values.comment2 + "\n\n" + values.c1.Performance;
        }
        if (values.comment1) {
          values.c1.Performance =
            values.comment1 + "\n\n" + values.c1.Performance;
        }

        if (isTeacherSubmit) {
          const responseCreate = await API.post("/termreport", values);
          if (responseCreate) {
            sessionStorage.setItem("lastSubmission", JSON.stringify(values));
            const responseSubmit = await API.put(
              `/termreport/submit/${responseCreate?.data?.Term_reportID}`
            );
            if (responseSubmit) {
              enqueueSnackbar("Report has been successfully Submitted", {
                variant: "success",
              });
            }
          }
        } else {
          await API.post("/termreport", values).then(async () => {
            enqueueSnackbar("Report has been successfully created", {
              variant: "success",
            });
            sessionStorage.setItem("lastSubmission", JSON.stringify(values));
          });
        }
      } else {
        const response = await API.put(
          `/termreport/edit/${selection.Term_reportID}`,
          values
        );

        if (response) {
          if (!isSubmit) {
            if (
              sessionStorage.getItem("role") !== "Campus Director" &&
              sessionStorage.getItem("role") !== "State Director"
            ) {
              enqueueSnackbar("Report has been successfully Edited", {
                variant: "success",
              });
              handleApproveUI();
            } else {
              if (values.feedback) {
                const response2 = await API.put(
                  `/termreport/reject/${selection.Term_reportID}`,
                  { Feedback: values.feedback }
                );
                if (response2) {
                  enqueueSnackbar("Feedback has been successfully submitted", {
                    variant: "success",
                  });
                  handleRejectUI(response2.data);
                  handleFeedbackClose();
                }
              } else if (isApprove) {
                const response2 = await API.put(
                  `/termreport/approve/${selection.Term_reportID}`,
                  {}
                );
                if (response2) {
                  enqueueSnackbar("Report has been successfully approved", {
                    variant: "success",
                  });
                  handleApproveUI();
                }
              } else {
                enqueueSnackbar("Report has been successfully Edited", {
                  variant: "success",
                });
                handleApproveUI();
              }
            }
          } else {
            const response = await API.put(
              `/termreport/submit/${selection.Term_reportID}`
            );
            if (response) {
              enqueueSnackbar("Report has been successfully Submitted", {
                variant: "success",
              });
              handleApproveUI();
            }
          }
        }
      }
      handleClose();
    } catch (error) {
      enqueueSnackbar("Report already exists", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function fetchData() {
      if (mode === "edit" && selection) {
        const response = await API.get(
          `/termreport/${selection.Term_reportID}`,
          { cancelToken: source.token }
        );
        const responseSubject = await API.get(`/subject/${selection.Subject}`);
        setReportData({
          StudentID: selection.StudentID,
          CentreID: selection.CentreID,
          TermID: selection.TermID,
          Subject: responseSubject.data[0],
          CourseGrade: selection.CourseGrade,
          a1: response.data[0],
          a2: response.data[1],
          a3: response.data[2],
          a4: response.data[3],
          a5: response.data[4],
          a6: response.data[5],
          p1: response.data[6],
          p2: response.data[7],
          p3: response.data[8],
          c1: response.data[9],
        });
      }
    }
    fetchData();
    return () => {
      source.cancel("request canceled");
    };
  }, [selection]);

  useEffect(() => {
    populateSubjects().then((subjectData) => {
      setSubjects(
        subjectData.sort((a, b) => {
          let fa = a.Name.toLowerCase(),
            fb = b.Name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
        })
      );
    });

    if (mode === "create") {
      populateComments().then((commentData) => {
        setComments(commentData);
      });
      getCurrentTerm().then((termData) => {
        setCurrTerm(JSON.stringify(termData));
      });
    }
  }, []);

  useEffect(() => {
    if (currTerm) {
      populateTerms().then((termData) => {
        setTerms(
          termData.map((t) => (
            <MenuItem key={t.TermID} value={JSON.stringify(t)}>
              {t.Name.replace(/ \(HK\)/, "")}
            </MenuItem>
          ))
        );
        if (mode === "create") {
          setReportData({
            StudentID: selection?.StudentID || "",
            CentreID: selection?.CentreID || "",
            Term:
              currTerm ||
              lastSubmission?.Term ||
              JSON.stringify(selection?.Term) ||
              "",
            Subject: lastSubmission?.Subject || selection?.Subject || "",
            CourseGrade:
              year?.FullName || selection?.Region_Year?.FullName || "",
            a1: {
              ObjectiveNumber: 1,
              Objective: "Understanding of key concepts",
              Performance: "",
            },
            a2: {
              ObjectiveNumber: 2,
              Objective: "Select a subject to view specialised objective",
              Performance: "",
            },
            a3: {
              ObjectiveNumber: 3,
              Objective: "Ability to complete class tasks",
              Performance: "",
            },
            a4: {
              ObjectiveNumber: 4,
              Objective: "Ability to complete homework tasks",
              Performance: "",
            },
            a5: {
              ObjectiveNumber: 5,
              Objective: "Performance in assessment tasks",
              Performance: "",
            },
            a6: {
              ObjectiveNumber: 6,
              Objective: "Overall improvement in application and performance",
              Performance: "",
            },
            p1: {
              ObjectiveNumber: 7,
              Objective: "Class participation, involvement and concentration",
              Performance: "",
            },
            p2: {
              ObjectiveNumber: 8,
              Objective: "Acceptance of advice & following instructions",
              Performance: "",
            },
            p3: {
              ObjectiveNumber: 9,
              Objective: "General conduct in class",
              Performance: "",
            },
            c1: {
              ObjectiveNumber: 10,
              Objective: "Comments",
              Performance: "",
            },
            feedback: "",
          });
        }
      });
    }
  }, [currTerm]);

  const isNotCDorSD = () => {
    if (
      sessionStorage.getItem("role") !== "Campus Director" &&
      sessionStorage.getItem("role") !== "State Director"
    )
      return true;
  };

  return (
    <Formik
      initialValues={reportData}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({ isSubmitting, isValid, values }) => {
        const isSSEPCourse = () => {
          if (values.Subject) {
            return values.Subject?.Report_subjectID === 75;
          }
        };

        return (
          <>
            <DialogTitle disableTypography className={classes.title}>
              <Typography variant="h5">
                {mode === "create" ? (
                  <>
                    Student Progress Report for {selection.GivenName}{" "}
                    {selection.Surname}
                  </>
                ) : (
                  <>
                    Student Progress Report for {selection.Student.GivenName}{" "}
                    {selection.Student.Surname}
                  </>
                )}
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Form id="term-report">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {subjects && (
                      <FormControl fullWidth>
                        <Field
                          name="Subject"
                          component={Autocomplete}
                          options={subjects}
                          getOptionLabel={(option) => option?.Name || ""}
                          renderInput={(params) => (
                            <MuiTextField {...params} label="Subject" />
                          )}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  {mode === "create" ? (
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="Term">Term</InputLabel>
                        <Field component={Select} name="Term" required>
                          {terms}
                        </Field>
                      </FormControl>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={2}>
                        <Typography variant="h6">Term:</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.body1} variant="body1">
                          {selection.Term.Name}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={2}>
                    <Typography variant="h6">Level:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.body1} variant="body1">
                      {mode === "create"
                        ? year.FullName.replace(/Year/, "Level")
                        : selection.Region_Year.FullName.replace(
                            /Year/,
                            "Level"
                          )}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography variant="h6">Campus:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.body1} variant="body1">
                      {selection.Centre.Name}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5">Academic Development</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>
                        {isSSEPCourse()
                          ? "Ability to complete testing tasks"
                          : "Understanding of key concepts"}
                      </InputLabel>
                      <Field component={Select} name="a1.Performance" required>
                        {renderMenuItems()}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>
                        {values.Subject
                          ? isSSEPCourse()
                            ? "Imporvement in writing & comprehension ability"
                            : values.Subject.Objective?.Objective
                          : "Select a subject to view specialised objective"}
                      </InputLabel>
                      <Field component={Select} name="a2.Performance" required>
                        {renderMenuItems()}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>
                        {isSSEPCourse()
                          ? "Improvement in mathematics ability"
                          : "Ability to complete class tasks"}
                      </InputLabel>
                      <Field component={Select} name="a3.Performance" required>
                        {renderMenuItems()}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>
                        {isSSEPCourse()
                          ? "Improvement in verbal reasoning ability"
                          : "Ability to complete homework tasks"}
                      </InputLabel>
                      <Field component={Select} name="a4.Performance" required>
                        {renderMenuItems()}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>
                        {isSSEPCourse()
                          ? "Improvement in numerical reasoning ability"
                          : "Performance in assessment tasks"}
                      </InputLabel>
                      <Field component={Select} name="a5.Performance" required>
                        {renderMenuItems()}
                        <MenuItem
                          key={"Not Applicable"}
                          value={"Not Applicable"}
                        >
                          Not Applicable
                        </MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>
                        {isSSEPCourse()
                          ? "Overall improvement in testing performance"
                          : "Overall improvement in application and performance"}
                      </InputLabel>
                      <Field component={Select} name="a6.Performance" required>
                        {renderMenuItems()}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5">Personal Development</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>
                        Class participation, involvement & concentration
                      </InputLabel>
                      <Field component={Select} name="p1.Performance" required>
                        {renderMenuItems()}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>
                        Acceptance of advice & following instructions
                      </InputLabel>
                      <Field component={Select} name="p2.Performance" required>
                        {renderMenuItems()}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>General conduct in class</InputLabel>
                      <Field component={Select} name="p3.Performance" required>
                        {renderMenuItems()}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5">Comments</Typography>
                  </Grid>

                  {mode === "create" && (
                    <>
                      <Grid item xs={12}>
                        <FormControl className={classes.select}>
                          <InputLabel>Comment about positive aspect</InputLabel>
                          <Field component={Select} name="comment1">
                            <MenuItem value="">None</MenuItem>
                            {renderCommentOne()}
                          </Field>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl className={classes.select}>
                          <InputLabel>
                            Comment about areas of improvement
                          </InputLabel>
                          <Field component={Select} name="comment2">
                            <MenuItem value="">None</MenuItem>
                            {renderCommentTwo()}
                          </Field>
                        </FormControl>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      {/* <InputLabel>Comments</InputLabel> */}
                      <Field
                        component={TextField}
                        name="c1.Performance"
                        multiline
                        rows={5}
                        variant="filled"
                        label="Free-form Comments"
                        required
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Dialog
                  disableBackdropClick
                  open={openFeedbackDialog}
                  onClose={handleFeedbackClose}
                >
                  <DialogContent>
                    <Typography variant="body1">
                      Reason for rejecting report: (max 500 characters)
                    </Typography>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        name="feedback"
                        multiline
                        variant="filled"
                        rows={5}
                        label="Feedback"
                        required
                      />
                    </FormControl>
                    {isSubmitting && <LinearProgress />}
                    <br />
                    <Button onClick={handleFeedbackClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      form="term-report"
                      type="submit"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                    >
                      Submit Feedback
                    </Button>
                  </DialogContent>
                </Dialog>
                {isSubmitting && <LinearProgress />}
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  {mode === "create" && (
                    <>
                      <Button
                        form="term-report"
                        type="submit"
                        color="primary"
                        disabled={!isValid || isSubmitting}
                      >
                        Save Draft Report
                      </Button>
                      <Button
                        form="term-report"
                        onClick={handleTeacherSubmit}
                        color="secondary"
                        disabled={!isValid || isSubmitting}
                      >
                        Submit Completed Report
                      </Button>
                    </>
                  )}
                  {mode === "edit" && (
                    <>
                      <Button
                        form="term-report"
                        type="submit"
                        color="secondary"
                        disabled={
                          !isValid ||
                          isSubmitting ||
                          (isNotCDorSD() && selection?.ReportState === 2) ||
                          (isNotCDorSD() && selection?.ReportState === 5) ||
                          (isNotCDorSD() && selection?.ReportState === 6) ||
                          (isNotCDorSD() && selection?.ReportState === 3)
                        }
                      >
                        Save Edits
                      </Button>
                      {sessionStorage.getItem("role") !== "Campus Director" &&
                        sessionStorage.getItem("role") !== "State Director" && (
                          <Button
                            form="term-report"
                            onClick={handleSubmitReport}
                            color="secondary"
                            disabled={
                              !isValid ||
                              isSubmitting ||
                              selection?.ReportState === 5 ||
                              selection?.ReportState === 6 ||
                              selection?.ReportState === 3
                              // || selection?.ReportState === 2
                            }
                          >
                            {selection?.ReportState === 2
                              ? "Resubmit Report"
                              : "Submit Report"}
                          </Button>
                        )}
                    </>
                  )}
                  {(sessionStorage.getItem("role") === "Campus Director" ||
                    sessionStorage.getItem("role") === "State Director") && (
                    <>
                      <Button
                        color="secondary"
                        form="term-report"
                        // type="submit"
                        disabled={
                          !isValid ||
                          isSubmitting ||
                          selection?.ReportState === 5 ||
                          selection?.ReportState === 6 ||
                          selection?.ReportState === 3 ||
                          selection?.ReportState === 4
                        }
                        onClick={handleApprove}
                      >
                        Approve
                      </Button>
                      <Button
                        disabled={
                          selection?.ReportState === 5 ||
                          selection?.ReportState === 6
                        }
                        onClick={handleFeedbackOpen}
                        color="primary"
                      >
                        Reject
                      </Button>
                    </>
                  )}
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                </div>
              </Form>
            </DialogContent>
          </>
        );
      }}
    </Formik>
  );
};
export default TermReportVIC;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Container, Grid, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
	heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  content: {
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#fafafa',
    width: 'auto',
    marginRight: 0,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    height: '80vh',
    width: `calc(100vh - ${drawerWidth}px)`,
    align: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    width: `calc(100vh - ${drawerWidth}px)`,
  },
  fixedHeight: {
    height: 240,
  },
	form: {
		'& > *': {
			margin: theme.spacing(1),
			width: 'auto',
			align: 'center',
		},
	},
}));

const UpdateDetails = () => {
	const classes = useStyles();

	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	}

	return (
    <main className={classes.content}>
      <Container className={classes.container}>
        <Typography variant="h3">Teacher Details</Typography>
				<br />
				<Accordion expanded={expanded === 'name'} onChange={handleChange('name')}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="namebh-content"
						id="namebh-header"
					>
						<Typography className={classes.heading}>Name</Typography>
						<Typography className={classes.secondaryHeading}>John Doe</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<form className={classes.form} noValidate>
							<TextField id="firstname" label="First Name" fullWidth />
							<TextField id="middlenames" label="Middle Name/s" />
							<TextField id="lastname" label="Last Name" />
						</form>
					</AccordionDetails>
					<AccordionActions>
						<Button size="small">Cancel</Button>
						<Button size="small" color="primary">Save</Button>
					</AccordionActions>
				</Accordion>
				<Accordion expanded={expanded === 'number'} onChange={handleChange('number')}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="numberbh-content"
						id="numberbh-header"
					>
						<Typography className={classes.heading}>Contact Number</Typography>
						<Typography className={classes.secondaryHeading}>0412 345 678</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<form className={classes.form} noValidate>
							<TextField id="number" label="Contact Number" />
						</form>
					</AccordionDetails>
					<AccordionActions>
						<Button size="small">Cancel</Button>
						<Button size="small" color="primary">Save</Button>
					</AccordionActions>
				</Accordion>
				<Accordion expanded={expanded === 'address'} onChange={handleChange('address')}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="addressbh-content"
						id="addressbh-header"
					>
						<Typography className={classes.heading}>Address</Typography>
						<Typography className={classes.secondaryHeading}>123 Fake Street Sydney 2000 NSW</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<form className={classes.form} noValidate>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField id="address1" label="Address line 1" fullWidth />
								</Grid>
								<Grid item xs={12}>
									<TextField id="address2" label="Address line 2" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField id="city" label="City / Suburb" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField id="state" label="State/Province/Region" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField id="zip" label="Zip / Postal code" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField id="country" label="Country" fullWidth />
								</Grid>
							</Grid>
						</form>
					</AccordionDetails>
					<AccordionActions>
						<Button size="small">Cancel</Button>
						<Button size="small" color="primary">Save</Button>
					</AccordionActions>
				</Accordion>
				<Accordion expanded={expanded === 'email'} onChange={handleChange('email')}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="emailbh-content"
						id="emailbh-header"
					>
						<Typography className={classes.heading}>Email</Typography>
						<Typography className={classes.secondaryHeading}>johndoe@gmail.com</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<form className={classes.form} noValidate>
							<TextField id="email" label="Email"/>
						</form>
					</AccordionDetails>
					<AccordionActions>
						<Button size="small">Cancel</Button>
						<Button size="small" color="primary">Save</Button>
					</AccordionActions>
				</Accordion>
				<Accordion expanded={expanded === 'wwccno'} onChange={handleChange('wwccno')}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="wwccnobh-content"
						id="wwccnobh-header"
					>
						<Typography className={classes.heading}>WWC Check Number</Typography>
						<Typography className={classes.secondaryHeading}>5675787657777</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<form className={classes.form} noValidate>
							<TextField id="wwccno" label="WWC Check Number" />
						</form>
					</AccordionDetails>
					<AccordionActions>
						<Button size="small">Cancel</Button>
						<Button size="small" color="primary">Save</Button>
					</AccordionActions>
				</Accordion>
      </Container>
    </main>
	)
}

export default UpdateDetails;
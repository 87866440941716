import {
  Font,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../assets/NSCC_logo.png";
import DejaVuSansRegular from "../assets/DejaVuSans-Regular.ttf";
import DejaVuSansItalic from "../assets/DejaVuSans-Italic.ttf";
import DejaVuSansBold from "../assets/DejaVuSans-Bold.ttf";
import DejaVuSansBI from "../assets/DejaVuSans-BoldItalic.ttf";
import pic1 from "../assets/NSCC_pic_1.png";
import watermark from "../assets/NSCC_notext.png";

Font.register({
  family: "DejaVuSans",
  fonts: [
    { src: DejaVuSansRegular },
    { src: DejaVuSansItalic, fontStyle: "italic" },
    { src: DejaVuSansBold, fontStyle: "bold" },
    { src: DejaVuSansBI, fontStyle: "BI" },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    fontFamily: "DejaVuSans",
    paddingTop: 20,
    paddingBottom: 75,
  },
  section: {
    padding: 5,
    flexGrow: 1,
  },
  img: {
    width: 140,
  },
  footer: {
    border: "2 solid black",
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: "5%",
  },
  title: {
    fontSize: 18,
    fontStyle: "italic",
    color: "#0070c0",
  },
  container: {
    flexDirection: "row",
    marginHorizontal: "5%",
    marginBottom: 10,
  },
  key: {
    border: "2 solid black",
    borderRadius: 30,
    marginHorizontal: "5%",
    marginBottom: 10,
  },
  italics: {
    fontSize: 16,
    fontStyle: "italic",
    color: "red",
  },
  bold: {
    fontSize: 11,
    fontStyle: "bold",
  },
  table: {
    display: "table",
    width: "90%",
    marginLeft: "5%",
    fontSize: 11,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "rgba(255, 255, 204, 0.8)",
    color: "black",
  },
  tableObjCol: {
    width: "65%",
  },
  tablePerCol: {
    width: "35%",
  },
  tableHeaderCell: {
    margin: "auto",
    margin: 5,
    fontSize: 16,
    color: "red",
    fontStyle: "italic",
    textDecoration: "underline",
  },
  tablePerCell: {
    margin: "auto",
    margin: 5,
    fontSize: 11,
    fontStyle: "italic",
  },
  tableObjCell: {
    margin: "auto",
    margin: 5,
    fontSize: 11,
    textAlign: "left",
  },
  text: {
    fontSize: 10,
    color: "#0070c0",
    paddingTop: 10,
  },
  rowPadding: {
    paddingBottom: 10,
    backgroundColor: "rgba(255, 255, 204, 0)",
  },
  // layer1: {
  //   width: "100%",
  //   height: "100%",
  // },
  layer2: {
    width: "100%",
    marginLeft: "-100%",
  },
  watermark: {
    position: "absolute",
    minWidth: "80%",
    minHeight: "30%",
    display: "flex",
    height: "30%",
    width: "80%",
    opacity: 0.05,
    transform: "translate(60, 200vh)",
  },
  redline: {
    height: "2px",
    width: "90%",
    alignSelf: "center",
    border: "2px solid red",
    borderRadius: "2px",
  },
  results: {
    border: "2px solid black",
    borderRadius: "2px",
  },
});

function compare(a, b) {
  if (a.ObjectiveNumber < b.ObjectiveNumber) {
    return -1;
  }
  if (a.ObjectiveNumber > b.ObjectiveNumber) {
    return 1;
  }
  return 0;
}

// Create Document Component
export function ReportPDFVIC(props) {
  const objectives = props?.values.Objectives;
  objectives.sort(compare);

  const principal = () => {
    return props?.values?.Centre?.PrintManagerName
      ? props?.values?.Centre?.Manager
      : props?.values?.Centre?.Region?.Region_Principal?.PrincipalName;
  };

  const isCampusDirector = () => props?.values?.Centre?.PrintManagerName;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={watermark} style={styles.watermark} />
        <View style={[styles.redline]} fixed />
        <View>
          <View
            style={[
              styles.container,
              { alignItems: "center", justifyContent: "center" },
            ]}
          >
            <View style={[styles.section, { flex: 1 }]}>
              <Image src={logo} style={styles.img} />
            </View>
            <View
              style={[
                styles.section,
                { alignItems: "center", textAlign: "center", flex: 1 },
              ]}
            >
              <Text style={[styles.title, { color: "red" }]}>
                Progress Report
              </Text>
              <View style={{ paddingBottom: 5 }} />
              <Text style={styles.title}>{props?.values.Subject}</Text>
              <View style={[styles.section, { flexDirection: "row" }]}>
                <Text style={[styles.title, { color: "red" }]}>
                  {props?.values.Term.Name.replace(/\d\d\d\d /g, " ")}
                </Text>
                <Text style={styles.title}>
                  {/\d\d\d\d/.exec(props?.values.Term.Name)}
                </Text>
              </View>
            </View>
            <View style={[styles.section, { flex: 1 }]}>
              <Image src={pic1} style={styles.img} />
            </View>
          </View>

          <View style={[styles.container, { marginTop: "1vh" }]}>
            <View style={[styles.section, { flexDirection: "row" }]}>
              <Text style={[styles.title, { fontStyle: "BI", fontSize: 13 }]}>
                Name:{" "}
              </Text>
              <Text style={[styles.title, { fontSize: 13 }]}>
                {props?.values.Student.GivenName}{" "}
                {props?.values.Student.Surname}
              </Text>
            </View>
            <View style={[styles.section, { flexDirection: "row" }]}>
              <Text
                style={[
                  styles.title,
                  { color: "black", fontStyle: "BI", fontSize: 13 },
                ]}
              >
                Year:{" "}
              </Text>
              <Text style={[styles.title, { color: "black", fontSize: 13 }]}>
                {props?.values.Region_Year.FullName.replace(/Year/, "")}
              </Text>
            </View>
            <View style={[styles.section, { flexDirection: "row" }]}>
              <Text
                style={[
                  styles.title,
                  { color: "black", fontStyle: "BI", fontSize: 13 },
                ]}
              >
                Campus:{" "}
              </Text>
              <Text style={[styles.title, { color: "black", fontSize: 13 }]}>
                {props?.values.Centre.Name}
              </Text>
            </View>
          </View>

          <View style={[styles.table, { marginBottom: 10, marginTop: "1vh" }]}>
            <View
              style={[
                styles.tableRow,
                { backgroundColor: "rgba(255, 255, 255, 0)" },
              ]}
            >
              <View style={styles.tableObjCol}>
                <Text style={styles.tableHeaderCell}>Area of Development</Text>
              </View>
              <View style={styles.tablePerCol}>
                <Text style={styles.tableHeaderCell}>Achievement</Text>
              </View>
            </View>
            <View style={styles.rowPadding} />
            <View style={styles.rowPadding} />
            <View style={styles.results}>
              <View
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    color: "#0070c0",
                  },
                ]}
              >
                <View style={styles.tableObjCol}>
                  <Text style={styles.tableObjCell}>
                    {props?.values.a1?.Objective || objectives[0].Objective}
                  </Text>
                </View>
                <View style={styles.tablePerCol}>
                  <Text style={styles.tablePerCell}>
                    {props?.values.a1?.Performance || objectives[0].Performance}
                  </Text>
                </View>
              </View>
              <View style={styles.rowPadding} />
              <View style={styles.tableRow}>
                <View style={styles.tableObjCol}>
                  <Text style={styles.tableObjCell}>
                    {props?.values.a2?.Objective || objectives[1].Objective}
                  </Text>
                </View>
                <View style={styles.tablePerCol}>
                  <Text style={styles.tablePerCell}>
                    {props?.values.a2?.Performance || objectives[1].Performance}
                  </Text>
                </View>
              </View>
              <View style={styles.rowPadding} />
              <View
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    color: "#0070c0",
                  },
                ]}
              >
                <View style={styles.tableObjCol}>
                  <Text style={styles.tableObjCell}>
                    {props?.values.a3?.Objective || objectives[2].Objective}
                  </Text>
                </View>
                <View style={styles.tablePerCol}>
                  <Text style={styles.tablePerCell}>
                    {props?.values.a3?.Performance || objectives[2].Performance}
                  </Text>
                </View>
              </View>
              <View style={styles.rowPadding} />
              <View style={styles.tableRow}>
                <View style={styles.tableObjCol}>
                  <Text style={styles.tableObjCell}>
                    {props?.values.a4?.Objective || objectives[3].Objective}
                  </Text>
                </View>
                <View style={styles.tablePerCol}>
                  <Text style={styles.tablePerCell}>
                    {props?.values.a4?.Performance || objectives[3].Performance}
                  </Text>
                </View>
              </View>
              <View style={styles.rowPadding} />
              <View
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    color: "#0070c0",
                  },
                ]}
              >
                <View style={styles.tableObjCol}>
                  <Text style={styles.tableObjCell}>
                    {props?.values.a5?.Objective || objectives[4].Objective}
                  </Text>
                </View>
                <View style={styles.tablePerCol}>
                  <Text style={styles.tablePerCell}>
                    {props?.values.a5?.Performance || objectives[4].Performance}
                  </Text>
                </View>
              </View>
              <View style={styles.rowPadding} />
              <View style={styles.tableRow}>
                <View style={styles.tableObjCol}>
                  <Text style={styles.tableObjCell}>
                    {props?.values.a6?.Objective || objectives[5].Objective}
                  </Text>
                </View>
                <View style={styles.tablePerCol}>
                  <Text style={styles.tablePerCell}>
                    {props?.values.a6?.Performance || objectives[5].Performance}
                  </Text>
                </View>
              </View>
              <View style={styles.rowPadding} />
              <View
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    color: "#0070c0",
                  },
                ]}
              >
                <View style={styles.tableObjCol}>
                  <Text style={styles.tableObjCell}>
                    {props?.values.p1?.Objective || objectives[6].Objective}
                  </Text>
                </View>
                <View style={styles.tablePerCol}>
                  <Text style={styles.tablePerCell}>
                    {props?.values.p1?.Performance || objectives[6].Performance}
                  </Text>
                </View>
              </View>
              <View style={styles.rowPadding} />
              <View style={styles.tableRow}>
                <View style={styles.tableObjCol}>
                  <Text style={styles.tableObjCell}>
                    {props?.values.p2?.Objective || objectives[7].Objective}
                  </Text>
                </View>
                <View style={styles.tablePerCol}>
                  <Text style={styles.tablePerCell}>
                    {props?.values.p2?.Performance || objectives[7].Performance}
                  </Text>
                </View>
              </View>
              <View style={styles.rowPadding} />
              <View
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    color: "#0070c0",
                  },
                ]}
              >
                <View style={styles.tableObjCol}>
                  <Text style={styles.tableObjCell}>
                    {props?.values.p3?.Objective || objectives[8].Objective}
                  </Text>
                </View>
                <View style={styles.tablePerCol}>
                  <Text style={styles.tablePerCell}>
                    {props?.values.p3?.Performance || objectives[8].Performance}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.container, { marginTop: 10 }]}>
            <View style={styles.section}>
              <Text style={styles.italics}>Teacher's Comment: </Text>
              <Text style={styles.text}>
                {props?.values.c1?.Performance || objectives[9].Performance}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{ position: "absolute", bottom: 20, left: 0, right: 0 }}
          fixed
        >
          <View
            style={[
              styles.container,
              {
                fontSize: `${isCampusDirector() ? 10 : 12}`,
              },
            ]}
          >
            <View
              style={[
                styles.section,
                { flexDirection: "row", flex: isCampusDirector() ? 1.2 : 1 },
              ]}
            >
              <Text style={{ color: "red" }}>Teacher: </Text>
              <Text>{props?.values?.CreatedByStaff?.ShortName || ""}</Text>
            </View>
            <View
              style={[
                styles.section,
                { flexDirection: "row", flex: isCampusDirector() ? 1.8 : 1 },
              ]}
            >
              {/* <Text style={{ color: "red" }}>Principal: </Text> */}
              <Text style={{ color: "red" }}>
                {isCampusDirector() ? "Campus Director: " : "Principal: "}
              </Text>
              {/* <Text>Christina Chia</Text> */}
              <Text>{principal()}</Text>
            </View>
            <View style={[styles.section, { flexDirection: "row", flex: 1 }]}>
              <Text style={{ color: "red" }}>www.north-shore.com.au</Text>
            </View>
          </View>
          <View style={[styles.redline]} />
        </View>
      </Page>
    </Document>
  );
}
